.sidebar{
  width: 25rem;
  background: $light_blue;
  min-height: calc(100vh - 10rem);
  ul{
    margin: 0;
    li{
      padding: 28px 11px 28px 34px;
      @extend .fs_14;
      line-height: 2rem;
      @extend .fw_600;
      @extend .text_uppercase;
      color: $dark_gray;
      @extend .cursor_pointer;
      letter-spacing: 0.2px;
      &:hover{
        background: $dark_blue;
        color: $white;
      }
      &.active{
        background: $dark_blue;
        color: $white;
      }
    }
  }
}
