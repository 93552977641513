// Table
.table_responsive {
  overflow-x: auto;
  min-height: 20rem;
  width: 100%;
  align-self: flex-start;

  .table {
    width: 100%;
    height: 100%;
    border-collapse: collapse;
    td{
      &.events, &.td_location, &.event_organized_td{
        text-decoration-line: underline;
        color: #1784E9;
        font-family: 'montserrat_medium';
        font-weight: 500;
      }
      .eventName_span{
        @include width(230px);
        display: block;
      }
    }

    thead {
      background: rgba($purple, 0.37);
      width: 100%;
      @extend .text_capitalize;

      tr {
        td {
          vertical-align: middle;
          font-size: 1.4rem;
          line-height: 2rem;
          color: $black;
          @extend .text_capitalize;
          @extend .montserrat_semibold;
          padding: 13px 6px 9px;

          .arrow_up {
            width: 1.6rem;
            @extend .cursor_pointer;

            // @extend .ml_05;
            img {
              width: 0.8rem;
              @extend .img_object_center;
            }
          }

          @media (max-width: 1600px) {
            font-size: 14px;
          }
        }
      }
    }

    tbody {
      tr {
        td {
          vertical-align: baseline;
          @extend .fs_13;
          font-size: 12px;
          line-height: 20px;
          letter-spacing: 0.3125px;
          color: $text_think;
          @extend .text_capitalize;
          font-family: "montserrat_medium";
          padding: 26px 10px 27px;
          border-bottom: 1px solid #ddd;
          text-transform: unset;

          .institution_name {
            font-family: montserrat_semibold;
            font-weight: 600;
            font-size: 12px;
            line-height: 20px;
            letter-spacing: 0.3125px;
            color: #3E4A58;
            display: block;
            padding-top: 2px;
          }

          a {
            color: $text_blue;
          }

          .actions_icon {
            @extend .d_flex_center;
            @extend .cursor_pointer;

            &.icon_lock {
              opacity: 0.5;
            }
            &.hashtagTab_lock{
              opacity: 60%;
            }

            &:not(:last-child) {
              margin-right: 6px;
            }

            img {
              max-width: 16px;
              min-width: 16px;
              width: 100%;
              height: 16px;
              display: block;
            }
          }

          &.td_emailphone {
            padding-bottom: 5px;

            // padding-top: 20px;
            span {
              display: block;

              &:empty {
                padding: 8px;
              }
            }
          }

          &.td_icons {
            padding-right: 30px;

            @media (max-width: 1679px) {
              padding-right: 20px;
            }

            .d_flex.icons {
              // justify-content: flex-end;
            }
          }
        }
      }
    }

    &.user_data_list {
      min-width: 110rem;
      table-layout: fixed;

      .ant-picker {
        border-radius: 20px;
      }

      .list_filter_modal {
        position: absolute;
        top: 82%;
        left: 0;
        min-width: 250px;
        max-width: 250px;
        width: 100%;
        z-index: 99;
        background: #ffffff;
        border-radius: 20px;
        line-height: 0;
        box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);

        &.location {
          min-width: 130px;
          max-width: 130px;
          padding-left: 0;
        }

        input {
          width: 100%;
          border: none;
          border-radius: 20px;
          background: #ffffff;
          padding: 4px 10px;
          font-family: montserrat_medium;
          font-weight: 500;
          font-size: 10px;
          line-height: 12px;
          text-align: left;
          letter-spacing: 0.3125px;
          color: #3E4A58;
        }
      }

      span.filter_dropdown.Stage {
        padding-left: 5px;
        cursor: pointer;

        img {
          display: inline-block;

          &:not(:last-child) {
            padding-right: 5px;
          }
        }
      }

      span.flag_num.Flags,
      span.added_on,
      .last_logged_on,
      .location {
        padding-left: 10px;
        display: inline-block;
        vertical-align: super;
        cursor: pointer;
      }

      span.added_on,
      .last_logged_on,
      .location {
        vertical-align: initial;
        cursor: pointer;
      }

      td {
        width: 180px;
        min-width: 180px;
        position: relative;
        &.user_action_pr{
          padding-right: 24px !important;
        }
        &:nth-child(1) {
          width: 35px;
          min-width: 35px;
          padding-right: 5px;

          img {
            display: inline-block;
            vertical-align: top;
            max-width: 100%;
            width: auto;
            margin: 0 auto;
          }
        }

        &:nth-child(2),
        &:nth-child(3) {
          width: 140px;
          min-width: 140px;

          @media (max-width: 1600px) {
            width: 150px;
            min-width: 150px;
          }
        }

        &:nth-child(4) {
          width: 270px;
          min-width: 270px;
        }

        &:nth-child(5) {
          width: 160px;
          min-width: 160px;
          text-align: left !important;

          @media (max-width: 1600px) {
            width: 180px;
            min-width: 180px;
          }
        }

        &:nth-child(5) {
          .list_filter_modal {
            left: -20px;
          }
        }
        &:nth-child(6) {
          width: 160px;
          min-width: 160px;
          text-align: left !important;

          @media (max-width: 1600px) {
            width: 180px;
            min-width: 180px;
          }
        }
        &:nth-child(7){
          width: 130px;
          min-width: 130px;

          @media (max-width: 1680px) {
            width: 180px;
            min-width: 180px;
          }
        }

        &:nth-child(8) {
          width: 130px;
          min-width: 130px;

          @media (max-width: 1680px) {
            width: 160px;
            min-width: 160px;
          }
        }

        &:nth-child(9) {
          width: 110px;
          min-width: 110px;
          @media (max-width: 1680px) {
            width: 150px;
            min-width: 150px;
          }
        }

        &:nth-child(10) {
          width: 160px;
          min-width: 160px;
          padding-right: 46px;
          // text-align: right;
          @media (max-width: 1600px) {
            width: 180px;
            min-width: 180px;
          }
        }

        &:nth-child(11) {
          width: 140px;
          min-width: 140px;
          padding-right: 32px;
          // text-align: right;
        }

        &.text_capitalize_checkbox {
          input {
            display: none;
          }

          label {
            width: 18px;
            height: 18px;
            border: 2px solid #636363;
            border-radius: 2px;
            display: block;
            position: relative;
            cursor: pointer;
          }

          input:checked+label:after {
            content: "";
            display: block;
            position: absolute;
            top: -3px;
            left: 0;
            bottom: 0;
            right: 0;
            margin: auto;
            width: 6px;
            height: 10px;
            border: solid #636363;
            border-width: 0 2px 2px 0;
            transform: rotate(45deg);
          }
        }
      }
    }

    &.specialistBasicView_list {
      tbody {
        td {
          height: 100px;
          vertical-align: text-top;
          font-size: 12px;
          &.td_icons{
            .icons{
              justify-content: flex-start !important;
            }
          }
        }
      }

      td {
        &.request_td {
          padding: 30px 18px;
          cursor: pointer;

          .request_block {
            display: grid;
            place-items: center;
          }

          &.request_send {
            background-color: #c00c2d;
          }

          &.request_accept {
            background-color: #ffbe1d;
          }
        }

        &:nth-child(1){
          padding-right: 2px;
        }
        &:nth-child(2),
        &:nth-child(3) {
          @media (min-width: 1670px) {
            width: 110px;
            min-width: 110px;
          }
        }

        &:nth-child(4) {
          @media (min-width: 1670px) {
            width: 250px;
            min-width: 250px;
          }
        }

        &:nth-child(5) {
          @media (min-width: 1670px) {
            width: 130px;
            min-width: 130px;
          }
        }

        &.request_send_yellow {
          background-color: #ffbe1d;
          padding: 30px 18px;
          height: 100px;
        }
        &:nth-child(6) {
          @media (min-width: 1670px) {
            width: 130px;
            min-width: 130px;
          }
        }
        &:nth-child(7) {
          width: 90px;
          min-width: 90px;

          @media (max-width: 1670px) {
            width: 130px;
            min-width: 130px;
          }
        }

        &:nth-child(8) {
          width: 160px;
          min-width: 160px;
          @media (max-width: 1670px) {
            width: 180px;
            min-width: 180px;
          }
        }

        &:nth-child(9) {
          width: 120px;
          min-width: 120px;
          @media (max-width: 1680px) {
            width: 130px;
            min-width: 130px;
          }
        }

        &:nth-child(10) {
          width: 100px;
          min-width: 100px;
          padding-right: 6px;
          text-align: left;
        }

        &:nth-child(11) {
          width: 135px;
          min-width: 135px;
          text-align: left;
          padding: 13px 6px 9px;

          @media (max-width: 1680px) {
            width: 160px;
            min-width: 160px;
          }
        }

        &:nth-child(12) {
          width: 45px;
          min-width: 45px;
          text-align: right;
          padding-right: 12px;
        }

        &:nth-child(13) {
          width: 52px;
          min-width: 52px;

          @media (max-width: 1680px) {
            width: 60px;
            min-width: 60px;
          }
        }
      }
    }

    &.specAdvanceView_list,
    &.user_Advanceview_list {
      td {

        &:nth-child(2),
        &:nth-child(3) {
          width: 120px;
          min-width: 120px;
          @media screen and (max-width: 1600px) {
            width: 140px;
            min-width: 140px;
          }
        }
        &:nth-child(4) {
          width: 220px;
          min-width: 220px;
          @media screen and (max-width: 1600px) {
            width: 300px;
            min-width: 300px;
          }
        }
        &:nth-child(6){
          width: 70px;
          min-width: 70px;
        }
        &:nth-child(7),&:nth-child(8) {
          width: 100px;
          min-width: 100px;
        }

        &:nth-child(11){
          width: 50px;
          min-width: 50px;
          @media screen and (max-width: 1600px) {
            width: 40px;
            min-width: 40px;
          }
        }
        &:nth-child(9),&:nth-child(10) {
          width: 100px;
          min-width: 100px;
          padding: 13px 6px 9px;
          text-align: left;

          @media (max-width: 1600px) {
            width: 120px;
            min-width: 120px;
          }
        }

        &:nth-child(5) {
          width: 70px;
          min-width: 70px;

          @media (max-width: 1600px) {
            width: 130px;
            min-width: 130px;
          }
        }

        &:nth-child(12) {
          width: 100px;
          min-width: 100px;

          @media (max-width: 1600px) {
            width: 130px;
            min-width: 130px;
          }
        }
        &:nth-child(13) {
          width: 120px;
          min-width: 120px;

          @media (max-width: 1600px) {
            width: 120px;
            min-width: 120px;
          }
        }

      }
    }

    &.specAdvanceView_list{
      td{
        &:nth-child(1) {
          width: 28px;
          min-width: 28px;
          @media screen and (max-width: 1680px) {
            width: 35px;
            min-width: 35px;
          }
        }
        &:nth-child(5){
          width: 120px;
          min-width: 120px;
        }
        &:nth-child(11) {
          width: 100px;
          min-width: 100px;
        }
        &:nth-child(6),  &:nth-child(12) {
          width: 80px;
          min-width: 80px;
        }
       &:nth-child(12) {
          width: 50px;
          min-width: 50px;
        }

      }
    }

    &.additionalmin_table {
      thead {
        td {
          background: #ebf3f8;
        }
      }

      tbody {
        tr {
          border-bottom: none;

          &:first-child {
            td {
              padding-top: 16px;
            }
          }
        }
      }

      td {
        font-family: montserrat_semibold;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        color: #000000;
        width: 100px;
        min-width: 100px;
        padding: 8px 5px;
        border-right: 2px solid #fff;
        border-bottom: none;

        span {
          display: block;
          font-size: 10px;
          line-height: 12px;
          color: #000000;
        }

        &:first-child {
          width: 140px;
          min-width: 140px;
        }

        &:last-child {
          width: 100px;
          min-width: 100px;
          text-align: right;
          padding-right: 12px;
          border: none;
        }

        &:nth-child(2) {
          padding-left: 28px;
          width: 140px;
          min-width: 140px;
        }

        &:nth-child(3) {
          padding-left: 16px;
          width: 140px;
          min-width: 140px;

          @media (max-width: 1500px) {
            width: 150px;
            min-width: 150px;
          }
        }
      }
    }

    &.cg_admin_post_list{
      thead{
        td{
          &:nth-child(1){
            width: 40px;
            min-width: 40px;
          }
          &:nth-child(2){
            width: 220px;
            min-width: 220px;
          }
          &:nth-child(3){
            width: 380px;
            min-width: 380px;
          }
          &:nth-child(4){
            width: 170px;
            min-width: 170px;
            .added_on{
              margin-left: 13px;
              display: inline-block;
              vertical-align: inherit;
              line-height: 0;
            }
          }
          &:nth-child(5){
            width: 250px;
            min-width: 250px;
          }
          &:nth-child(6){
            width: 85px;
            min-width: 85px;
          }
          &:nth-child(7){
            width: 140px;
            min-width: 140px;
          }
          &:nth-child(8){
            width: 100px;
            min-width: 100px;
            .added_on{
              margin-left: 13px;
              display: inline-block;
              vertical-align: inherit;
              line-height: 0;
            }
          }
          &:nth-child(9){
            width: 100px;
            min-width: 100px;
          }
          &:nth-child(10){
            width: 150px;
            min-width: 150px;
            .filter_dropdown {
              margin-left: 5px;
              filter: brightness(0%);
              cursor: pointer;
            }
          }

        }
      }
      &.spec_mng_post_list{
        thead{
          td{
            .added_on{
              margin-left: 12px;
            }
            &:nth-child(1){
              width: 30px;
              min-width: 30px;
              @media screen and (max-width: 1600px) {
                width: 35px;
                min-width: 35px;
              }
            }
            &:nth-child(2){
              width: 450px;
              min-width: 450px;
              @media screen and (max-width: 1600px) {
                width: 540px;
                min-width: 540px;
              }
            }
            &:nth-child(3){
              width: 140px;
              min-width: 140px;
              @media screen and (max-width: 1600px) {
                width: 160px;
                min-width: 160px;
              }
            }
            &:nth-child(4){
              width: 70px;
              min-width: 70px;
              @media screen and (max-width: 1600px) {
                width: 90px;
                min-width: 90px;
              }
            }
            &:nth-child(5){
              width: 100px;
              min-width: 100px;
              @media screen and (max-width: 1600px) {
                width: 130px;
                min-width: 130px;
              }
            }
            &:nth-child(6){
              width: 80px;
              min-width: 80px;
              @media screen and (max-width: 1600px) {
                width: 100px;
                min-width: 100px;
              }
            }
            &:nth-child(7){
              width: 160px;
              min-width: 160px;
            }
          }
        }
      }
      tbody{
        .td_icons{
          .icons{
            justify-content: flex-start !important;
            .actions_icon{
              img{
                max-width: 18px;
              }
            }
          }
        }
        td{
          &.td_lastLogin.td_post{
            font-size: 13px;
            line-height: 17px;
            color: #3079EF;
            font-family: "montserrat_medium";
            font-weight: 500;
            span{
              cursor: pointer;
              max-width: 380px;
              width: 100%;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              overflow: hidden;
            }
          }
          &.td_three_dots{
            span{
              display: inline-block !important;
              width: 426px !important;
              white-space: nowrap !important;
              overflow: hidden !important ;
              text-overflow: ellipsis !important;
            }
          }
          &.promote_checkbox{
           @extend .comma_input_css;
          }
          &.text_capitalize_user{
            span{
              color: #3079EF;
              cursor: pointer;
              font-family: "montserrat_medium";
              font-weight: 500;
            }
          }
        }
      }
    }

    &.cg_admin_post_list {
      &.video_post{
        thead{
          td{
            &:nth-child(1){
              width: 40px;
              min-width: 40px;
            }
            &:nth-child(2){
              width: 220px;
              min-width: 220px;
            }
            &:nth-child(3){
              width: 380px;
              min-width: 380px;
            }
            &:nth-child(4){
              width: 170px;
              min-width: 170px;
              .added_on{
                margin-left: 13px;
                display: inline-block;
                vertical-align: inherit;
                line-height: 0;
              }
            }
            &:nth-child(5){
              width: 250x;
              min-width: 250px;
            }
            &:nth-child(6){
              width: 150px;
              min-width: 150px;
              .filter_dropdown {
                margin-left: 5px;
                filter: brightness(0%);
                cursor: pointer;
              }
            }
  
          }
        }
      }
      &.spec_mng_post_list{
        thead{
          td{
            .added_on{
              margin-left: 12px;
            }
            &:nth-child(1){
              width: 30px;
              min-width: 30px;
              @media screen and (max-width: 1600px) {
                width: 35px;
                min-width: 35px;
              }
            }
            &:nth-child(2){
              width: 450px;
              min-width: 450px;
              @media screen and (max-width: 1600px) {
                width: 540px;
                min-width: 540px;
              }
            }
            &:nth-child(3){
              width: 140px;
              min-width: 140px;
              @media screen and (max-width: 1600px) {
                width: 160px;
                min-width: 160px;
              }
            }
            &:nth-child(4){
              width: 70px;
              min-width: 70px;
              @media screen and (max-width: 1600px) {
                width: 90px;
                min-width: 90px;
              }
            }
            &:nth-child(5){
              width: 100px;
              min-width: 100px;
              @media screen and (max-width: 1600px) {
                width: 130px;
                min-width: 130px;
              }
            }
            &:nth-child(6){
              width: 80px;
              min-width: 80px;
              @media screen and (max-width: 1600px) {
                width: 100px;
                min-width: 100px;
              }
            }
            &:nth-child(7){
              width: 160px;
              min-width: 160px;
            }
          }
        }
      }
      tbody{
        .td_icons{
          .icons{
            justify-content: flex-start !important;
            .actions_icon{
              img{
                max-width: 18px;
              }
            }
          }
        }
        td{
          &.td_lastLogin.td_post{
            font-size: 13px;
            line-height: 17px;
            color: #3079EF;
            font-family: "montserrat_medium";
            font-weight: 500;
            span{
              cursor: pointer;
              max-width: 380px;
              width: 100%;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              overflow: hidden;
            }
          }
          &.td_three_dots{
            span{
              display: inline-block !important;
              width: 426px !important;
              white-space: nowrap !important;
              overflow: hidden !important ;
              text-overflow: ellipsis !important;
            }
          }
          &.promote_checkbox{
           @extend .comma_input_css;
          }
          &.text_capitalize_user{
            span{
              color: #3079EF;
              cursor: pointer;
              font-family: "montserrat_medium";
              font-weight: 500;
            }
          }
        }
      }
    }


    &.support_org_table{
          td{
            &.td_location{
              padding: 26px 20px 27px 10px;
            }
            &:nth-child(1){
              width: 22px;
              min-width: 22px;
              @media (max-width: 1680px) {
                width: 26px;
                min-width: 26px;
              }
            }
            &:nth-child(2){
              width: 170px;
              min-width: 170px;
              @media (max-width: 1680px) {
                width: 150px;
                min-width: 150px;
              }
            }

            &:nth-child(3){
              width: 170px;
              min-width: 170px;
              @media (max-width: 1680px) {
                width: 160px;
                min-width: 160px;
              }
              @media (max-width: 1600px) {
                width: 190px;
                min-width: 190px;
              }
            }
            &:nth-child(4){
              width: 170px;
              min-width: 170px;
              @media (max-width: 1680px) {
                width: 160px;
                min-width: 160px;
              }
              @media (max-width: 1600px) {
                width: 190px;
                min-width: 190px;
              }
            }
            &:nth-child(5){
              width: 200px;
              min-width: 200px;
              @media (max-width: 1680px) {
                width: 250px;
                min-width: 250px;
              }
              @media (max-width: 1600px) {
                width: 200px;
                min-width: 200px;
              }
            }

            &:nth-child(6){
              width: 100px;
              min-width: 100px;
              @media (max-width: 1600px) {
                width: 120px;
                min-width: 120px;
              }
            }
        &:nth-child(7){
              width: 60px;
              min-width: 60px;
              @media (max-width: 1600px) {
                width: 80px;
                min-width: 80px;
              }
            }
            &:nth-child(8){
              width: 100px;
              min-width: 50px;
              @media (max-width: 1600px) {
                width: 80px;
                min-width: 80px;
              }
            }
            &:nth-child(9){
              width: 80px;
              min-width: 80px;
              padding-right: 6px;
              text-align: left;
              @media (max-width: 1680px) {
                width: 100px;
                min-width: 100px;
              }
              .icons{
                justify-content: flex-start !important;
              }
            }
          }
    }

    &.events_activits_list{
      td{
        &:nth-child(1){
          width: 38px;
          min-width: 38px;
          @media (max-width: 1700px) {
            width: 44px;
            min-width: 44px;
          }
        }
        &:nth-child(2){
          width: 230px;
          min-width: 230px;
          @media (max-width: 1700px) {
            width: 260px;
            min-width: 260px;
          }
        }
        &:nth-child(3){
          width: 320px;
          min-width: 320px;
          @media (max-width: 1700px) {
            width: 280px;
            min-width: 280px;
          }
        }
        &:nth-child(4){
          width: 180px;
          min-width: 180px;
          @media (max-width: 1700px) {
            width: 170px;
            min-width: 170px;
          }
        }
        &:nth-child(5){
          width: 220px;
          min-width: 220px;
          @media (max-width: 1700px) {
            width: 200px;
            min-width: 200px;
          }
        }
        &:nth-child(6){
          width: 280px;
          min-width: 280px;
          @media (max-width: 1700px) {
            width: 240px;
            min-width: 240px;
          }
        }
        &:nth-child(7){
          width: 180px;
          min-width: 180px;
          @media (max-width: 1700px) {
            width: 200px;
            min-width: 200px;
          }
        }
        &.td_icons{
          .icons{
            justify-content: flex-start !important;
          }
        }
        .added_on{
          img{
            margin: 0 10px;
          }
        }
      }
    }
    &.Promotion_Units{
      td{
        &:nth-child(1){
          width: 38px;
          min-width: 38px;
          @media (max-width: 1024px) {
            width: 44px;
            min-width: 44px;
          }
        }
        &:nth-child(2){
          width: 250px;
          min-width: 250px;
          @media (max-width: 1024px) {
            width: 260px;
            min-width: 260px;
          }
        }
        &:nth-child(3){
          width: 196px;
          min-width: 196px;
          @media (max-width: 1024px) {
            width: 116px;
            min-width: 116px;
          }
        }
        &:nth-child(4){
          width: 200px;
          min-width: 200px;
          @media (max-width: 1024px) {
            width: 140px;
            min-width: 140px;
          }
        }
        &:nth-child(5){
          width: 174px;
          min-width: 174px;
          @media (max-width:1024px) {
            width: 100px;
            min-width: 100px;
          }
        }
        &:nth-child(6){
          width: 71px;
          min-width: 71px;
          @media (max-width:1024px) {
            width: 40px;
            min-width: 40px;
          }
        }
        &:nth-child(7){
          width: 122px;
          min-width: 122px;
          @media (max-width: 1024px) {
            width: 200px;
            min-width: 200px;
          }
        }
        &:nth-child(8){
          width: 122px;
          min-width: 122px;
          @media (max-width:1024px) {
            width: 200px;
            min-width: 200px;
          }
        }
        &:nth-child(9){
          width: 120px;
          min-width: 120px;
          @media (max-width:1024px) {
            width: 100px;
            min-width: 100px;
          }
        }
        &:nth-child(10){
          width: 140px;
          min-width: 140px;
          @media (max-width:1024px) {
            width: 100px;
            min-width: 100px;
          }
        }
        &:nth-child(11){
          width: 102px;
          min-width: 102px;
          @media (max-width:1024px) {
            width: 100px;
            min-width: 100px;
          }
        }
        &:nth-child(12){
          width: 180px;
          min-width: 180px;
          @media (max-width: 1700px) {
            width: 200px;
            min-width: 200px;
          }
        }
        &.td_icons{
          .icons{
            justify-content: flex-start !important;
          }
        }
        .added_on{
          img{
            margin: 0 10px;
          }
        }
      }
    }
    &.hashTag_list{
      td{
        &:nth-child(1){
          width: 38px;
          min-width: 38px;
          @media (max-width: 1700px) {
            width: 44px;
            min-width: 44px;
          }
        }
        &:nth-child(2){
          width: 320px;
          min-width: 320px;
          @media (max-width: 1700px) {
            width: 280px;
            min-width: 280px;
          }
        }
        &:nth-child(3){
          width: 320px;
          min-width: 320px;
          @media (max-width: 1700px) {
            width: 280px;
            min-width: 280px;
          }
        }
        &:nth-child(4){
          width: 180px;
          min-width: 180px;
          @media (max-width: 1700px) {
            width: 170px;
            min-width: 170px;
          }
        }
        &:nth-child(5){
          width: 220px;
          min-width: 220px;
          @media (max-width: 1700px) {
            width: 200px;
            min-width: 200px;
          }
        }
        &:nth-child(6){
          width: 180px;
          min-width: 180px;
          @media (max-width: 1700px) {
            width: 200px;
            min-width: 200px;
          }
        }
        &:nth-child(7){
          width: 180px;
          min-width: 180px;
          @media (max-width: 1700px) {
            width: 200px;
            min-width: 200px;
          }
        }
        &.td_icons{
          .icons{
            justify-content: flex-start !important;
          }
        }
        .added_on{
          img{
            margin: 0 10px;
          }
        }
      }
      tbody{
        td{
          &.hashtag_checkbox{
           @extend .comma_input_css;
          }
        }
      }
    }
    &.hashtag_group_list{
      td{
        &:nth-child(1){
          width: 38px;
          min-width: 38px;
          @media (max-width: 1700px) {
            width: 44px;
            min-width: 44px;
          }
        }
        &:nth-child(2){
          width: 600px;
          min-width: 600px;
          @media (max-width: 1700px) {
            width: 620px;
            min-width: 620px;
          }
        }
        &:nth-child(3){
          width: 320px;
          min-width: 320px;
          @media (max-width: 1700px) {
            width: 280px;
            min-width: 280px;
          }
        }
        &:nth-child(4){
          width: 180px;
          min-width: 180px;
          @media (max-width: 1700px) {
            width: 170px;
            min-width: 170px;
          }
        }
        &:nth-child(5){
          width: 180px;
          min-width: 180px;
          @media (max-width: 1700px) {
            width: 200px;
            min-width: 200px;
          }
        }
        &.td_icons{
          .icons{
            justify-content: flex-start !important;
          }
        }
        .added_on{
          img{
            margin: 0 10px;
          }
        }
      }
    }
    &.groups_list{
      td{
        &:nth-child(1){
          width: 38px;
          min-width: 38px;
          @media (max-width: 1024px) {
            width: 44px;
            min-width: 44px;
          }
        }
        &:nth-child(2){
          width: 250px;
          min-width: 250px;
          @media (max-width: 1024px) {
            width: 260px;
            min-width: 260px;
          }
        }
        &:nth-child(3){
          width: 196px;
          min-width: 196px;
          @media (max-width: 1024px) {
            width: 116px;
            min-width: 116px;
          }
        }
        &:nth-child(4){
          width: 200px;
          min-width: 200px;
          @media (max-width: 1024px) {
            width: 140px;
            min-width: 140px;
          }
        }
        &:nth-child(5){
          width: 174px;
          min-width: 174px;
          @media (max-width:1024px) {
            width: 100px;
            min-width: 100px;
          }
        }
        &:nth-child(6){
          width: 100px;
          min-width: 100px;
          @media (max-width:1024px) {
            width: 60px;
            min-width: 60px;
          }
        }
        &:nth-child(7){
          width: 120px;
          min-width: 120px;
          @media (max-width:1024px) {
            width: 100px;
            min-width: 100px;
          }
        }
        &:nth-child(8){
          width: 140px;
          min-width: 140px;
          @media (max-width:1024px) {
            width: 100px;
            min-width: 100px;
          }
        }
        &:nth-child(9){
          width: 102px;
          min-width: 102px;
          @media (max-width:1024px) {
            width: 100px;
            min-width: 100px;
          }
        }
        &:nth-child(10){
          width: 180px;
          min-width: 180px;
          @media (max-width: 1700px) {
            width: 200px;
            min-width: 200px;
          }
        }
        &.td_icons{
          .icons{
            justify-content: flex-start !important;
          }
        }
        .added_on{
          img{
            margin: 0 10px;
          }
        }
      }
    }
  }
}


.comma_input_css{
  input{
    appearance: none;
  }
  label{
    @extend .comman_label_before;
    position: relative;
    &::after {
      @extend .comman_label_after;
    }
  }
  input:checked + label:after{
    display: block;
  }
}

.comman_label_after{
    content: '';
    position: absolute;
    top: 1px;
    left: 3px;
    width: 4px;
    height: 7px;
    border: solid #595959;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    display: none;
}
.comman_label_before{
  display: inline-block;
  width: 14px;
  height: 14px;
  border: 2px solid #636363;
  border-radius: 2px;
  cursor: pointer;
}
.cg_filter, .filter_dropdown {
  img {
    width: 16px;
    height: 17px;
  }
}
.td_likeIcon, .likeicon_up, .likeicon_down{
  display: inline-block;
  vertical-align: initial;
}
.td_likeIcon{
  font-size: 0;
  line-height: 0;
  vertical-align: text-top;
  margin-left: 7px;
  .likeicon_up{
    margin-right: 2px;
  }
  .likeicon_down, .likeicon_up{
    cursor: pointer;
  }
}