.main_promotion_block{
    position: relative;
    .promotion_header{
        width: 100%;
        background-color: rgba(154, 176, 189, 0.19);
        align-items: center;
        justify-content: space-between;
        // padding: 28px 20px 27px 40px;
        .custom-select select {
            margin: 0;
            font-family: montserrat_bold;
            font-weight: 700;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.3125px;
            text-transform: uppercase;
            color: #FFFFFF;
            padding: 28px 95px 27px 53px !important;
            max-width:360px;
            width: 100%;
            // background-color: #FFFFFF !important;
            background-image: url("../images/white_down_arrow.png") !important;
            background-color: $dark_blue;
        }
        .custom-select select option {
            background: #CED5DA;
            color: #5D737E;
        }
        .archived{
            font-family: montserrat_bold;
            font-weight: 700;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.3125px;
            text-transform: uppercase;
            align-self: center;
            padding-left: 22px;
        }
        .btn_duplicate{
          background: #495568;
          border-radius: 10px;
          max-width:133px;
          width: 100%;
          color: #FFFFFF;
          font-family:montserrat_semibold;
          font-weight: 600;
          border: unset;
          height: 42px;
          margin-right: 31px;
        }
        .btn_duplicate_disable{
          background: rgba(73, 85, 104, 0.3);
        }
        .broadcast{
          margin: 0;
          font-family: montserrat_bold;
          font-weight: 700;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.3125px;
          text-transform: uppercase;
          color: #FFFFFF;
          padding: 28px 95px 27px 53px !important;
          max-width:360px;
          width: 100%;
          background-color: $dark_blue;
        }
    }
    .promotion_fields_block{
        padding: 20px 40px;
        &.spotlight_field{
          input,textarea{
            font-family: montserrat_regular;
          }   
        }
        .all_deatil{
          
            .ant-row.ant-form-item-row{
                flex-direction: column;
            }
            .ant-form-item-label > label::after{
                content: '';
            }
            .ant-form-item-label > label{
                height: auto;
                line-height: 20px;
            }
            .pro_name,.pro_email,.add_tag,.org_buttons{
                max-width: 500px;
                width: 100%;
            }
            .broadcast_desc{
              max-width: 500px;
              width: 100%;
            }
            .select_user_btn{
              .form_control{
                max-width: 200px !important;
                width: 100% !important;
                background-color: #ecf0f3;
                border-radius: 1rem;
                border: 2.5px solid #DEDEDE !important;
                outline: none;
                text-align: left;
              }
              .selected_user_count{
                background: transparent;
                border: none;
                text-align: right;
                text-decoration-line: underline;
                color: #1784E9;
                font-family: montserrat_semibold;
                font-weight: 600;
                font-size: 14px;
                line-height: 20px;
                letter-spacing: 0.3125px;
                margin-left: 20px;
              }
            }
        }
    }
    .datepicker_field{
       @include datetime_picker;
    }
    .check_checkbox{
      display: flex;
      margin-top: 10px;
      align-items: baseline;
      margin-left: 26px;
      position: relative;
     label{
       margin-left: 24px;
       color: #457B9D;
     }
     input {
       position: absolute;
       opacity: 0;
       cursor: pointer;
       z-index: 1;
       margin-top: 3px;
     }
     .checkmark {
     position: absolute;
     top: 0;
     left: 0;
     height: 14px;
     width: 14px;
     background-color:#FFFFFF;
     border: 2px solid #595959;
     margin-top: 3px;
     &:after {
       content: "";
       position: absolute;
       display: none;
       left: 2px;
       top: 0px;
       width: 5px;
       height: 8px;
       border: solid black;
       border-width: 0 3px 3px 0;
       -webkit-transform: rotate(45deg);
       -ms-transform: rotate(45deg);
       transform: rotate(45deg);
     }
     }
     input {
       &:checked {
         ~ {
           .checkmark {
             background-color:  #FFFFFF;
             &:after {
               display: block;
             }
           }
         }
       }
     }
    }
    .promotion_format{
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 30px !important;
      .form_control {
        max-width: 200px !important;
        width: 100% !important;
      }
    }
    .upload_video{
      .video_btn{
        background: #495568;
        border-radius: 10px;
        color: #FFFFFF;
        font-family: montserrat_semibold;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        letter-spacing: 0.3125px;
        height: 42px !important;
        padding: 0 45px;
      }
    }
   .fill{
     &::after{
       top: 8px;
     }
   }
   .Expires_at{
     font-family: montserrat_regular;
     font-size: 14px;
     font-weight: 600;
     line-height: 20px;
     letter-spacing: 0.3125px;
     color: #595959;
     align-self: center;
     margin-bottom: 24px;
   }
    .upload_btn_main{
      span{
        @extend .comman_org_font_style;
        display: inline-block;
        margin-left: 7px;
        cursor: pointer;
      }
    }
    .add_tag_small{
      max-width: 201px;
      width: 100%;
    }
    .text_add {
      .clear_btn{
        color: #1784E9;
      }
    }
    .org_buttons{
      .btn_primary{
        font-family: montserrat_regular;
        font-weight: 400;
      }
    }
    .main_covor_img{
      background-color: #D9D9D9;
      // padding: 125px 112px;
      margin-right: 34px;
      margin-bottom: 26px;
      max-width: 375px;
      width: 100%;
      border-radius: 5px;
     
      &.img_169{
        video{
          border-radius: 5px;
          object-fit: contain;
        }
      }
      img{
        width: 100%;
        height: 400px;
        border-radius: 5px;
        object-fit: cover;
      }
      video{
        border-radius: 5px;
        object-fit: cover;
      }
      .video-react {
        border-radius: 5px;
        .video-react-big-play-button{
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
      &.default{
        padding: 125px 0;
        text-align: center;
        img{
          width: 150px;
          height: 150px;
        }
      }
    }
}
.UpdateStatusHashTag,.EditHashTags,.AddHashTags {
  .hashtag_popup{
    .ant-input[disabled]{
      color: #3E4A58 !important;
      background-color: #E4E4E4 !important;
      border: 1.5px solid #DEDEDE !important;
      font-family: montserrat_medium;
      font-weight: 500;
    }
    .ant-select-show-search{
      .ant-select-selector{
        background: #F8F8F8;
        border: 1.5px solid #DEDEDE;
        border-radius: 10px;
        padding: 6px 0;
       
        .ant-select-selection-item{
          // border: transparent;
          // background: transparent;
          &-content{
            font-family: montserrat_medium;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.3125px;
            color: #3E4A58;
          }
          // &-remove{
          //   display: none;
          // }
        }
      }
    }
  }
}
.AddHashTags,.EditHashTags,.AddHashGroups,.EditHashGroups,.UpdateStatusHashTag  {
  .all_deatil{
    &.hashtag_popup h5{
      font-family:montserrat_semibold;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #000000;
      text-align: center;
      margin-bottom: 34px;
    }
    .ant-form-item{
      margin-bottom: 0;
    }
    .Whitespace{
      font-family: montserrat_medium;
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;
      letter-spacing: 0.3125px;
      color: rgba(99, 99, 99, 0.8);
      margin-bottom: 26px;
    }
    .org_buttons .btn_primary ,.org_buttons .clear_btn{
      font-weight: 400;
      font-family: 'montserrat_medium' ;
    }
  }
}