// Font Size
.fs_9 {
  font-size: .9rem;
}

.fs_12 {
  font-size: 1.2rem;
}

.fs_13 {
  font-size: 1.3rem;
}

.fs_14 {
  font-size: 1.4rem;
}

.fs_20 {
  font-size: 2rem;
}

.fs_28 {
  font-size: 2.8rem;
}


// font Weight
.fw_600 {
  @extend .montserrat_bold;
}

// .fw_500{ @extend .montserrat_semibold;}
.fw_400 {
  @extend .montserrat_light;
}

.fw_300 {
  @extend .montserrat_thin;
}

// Letter spacing
.ls_03 {
  letter-spacing: .03rem;
}

.ls_07 {
  letter-spacing: .07rem;
}

.text_capitalize_user {
  text-transform: capitalize !important;
}

.dot_blue {
  background-color: $blue_dot;
  height: .4rem;
  width: .4rem;
}

// Heading Tags
h1,
h2,
h3,
h4,
h5,
h6 {
  @extend .montserrat_bold;
  color: $black;
}

h2 {
  @extend .fs_28;
  line-height: 40px;
  color: $deep_black;
  @extend .ls_07;
}

.order_asc {
  transform: rotate(180deg);
}

h4 {
  @extend .fs_20;
  line-height: 40px;
  color: $white;
  @extend .text_center;
  @extend .ls_07;
  @extend .text_uppercase;
}

// Margin - Padding
.m_0 {
  margin: 0;
}

.mt_05 {
  margin-top: .5rem;
}

.mt_1 {
  margin-top: 1rem;
}

.mt_2 {
  margin-top: 1.5rem;
}

.mt_3 {
  margin-top: 3rem;
}

.mt_4 {
  margin-top: 4rem;
}

.mb_05 {
  margin-bottom: .5rem;
}

.mb_1 {
  margin-bottom: 1rem;
}

.mb_2 {
  margin-bottom: 1.5rem;
}

.mb_3 {
  margin-bottom: 3rem;
}

.mb_4 {
  margin-bottom: 4rem;
}

.mb_60 {
  margin-bottom: 6rem;
}

.ml_05 {
  margin-left: .5rem;
}

.ml_1 {
  margin-left: 1rem;
}

.ml_2 {
  margin-left: 1.5rem;
}

.ml_3 {
  margin-left: 3rem;
}

.ml_4 {
  margin-left: 4rem;
}

.mr_05 {
  margin-right: .5rem;
}

.mr_1 {
  margin-right: 1rem;
}

.mr_2 {
  margin-right: 1.5rem;
}

.mr_3 {
  margin-right: 3rem;
}

.mr_4 {
  margin-right: 4rem;
}

.mx_auto {
  margin: 0 auto;
}

.p_0 {
  padding: 0;
}

.pt_1 {
  padding-top: 1rem;
}

.pt_2 {
  padding-top: 1.5rem;
}

.pt_3 {
  padding-top: 3rem;
}

.pt_4 {
  padding-top: 4rem;
}

.pb_1 {
  padding-bottom: 1rem;
}

.pb_2 {
  padding-bottom: 1.5rem;
}

.pb_3 {
  padding-bottom: 3rem;
}

.pb_4 {
  padding-bottom: 4rem;
}

.pl_1 {
  padding-left: 1rem;
}

.pl_2 {
  padding-left: 1.5rem;
}

.pl_3 {
  padding-left: 3rem;
}

.pl_4 {
  padding-left: 4rem;
}

.pr_1 {
  padding-right: 1rem;
}

.pr_2 {
  padding-right: 1.5rem;
}

.pr_3 {
  padding-right: 3rem;
}

.pr_4 {
  padding-right: 4rem;
}

.px_auto {
  padding: 0 1.5rem;
}




.table_responsive::-webkit-scrollbar {
  height: .5rem;
  width: .5rem;
}

/* width */
.table_responsive::-webkit-scrollbar-track {
  background: $light_silver;
}

/* Track */
.table_responsive::-webkit-scrollbar-thumb {
  background: $dark_silver;
}

/* Handle */
.table_responsive::-webkit-scrollbar-thumb:hover {
  background: $silver_gray;
}

/* Handle on hover */

:root {
  @include comman_scrollbar_firefox(#3E4A58, #BFC6CF);
}

::-webkit-scrollbar {
  width: .5rem;
}

/* width */
::-webkit-scrollbar-track {
  background: $light_silver;
}

/* Track */
::-webkit-scrollbar-thumb {
  background: $dark_silver;
}

/* Handle */
::-webkit-scrollbar-thumb:hover {
  background: $silver_gray;
}

/* Handle on hover */

.resize {
  resize: none;
}

.w_100 {
  width: 100%;
}

// Positions
.position_fixed {
  position: fixed;
}

.position_relative {
  position: relative;
}

.position_absolute {
  position: absolute;
}

// General
.fullWidth {
  float: left;
  width: 100%;
}

.border_0 {
  border: 0;
}

.cursor_pointer {
  cursor: pointer;
}

.cursor_initial {
  cursor: initial;
}

.disabled {
  pointer-events: none;
  cursor: not-allowed;
}

.bg_transparent {
  background: transparent
}

// Text
.text_left {
  text-align: left;
}

.text_center {
  text-align: center;
}

.text_right {
  text-align: right;
}

.text_uppercase {
  text-transform: uppercase;
}

.text_capitalize {
  text-transform: capitalize;
}

.text_decoration_none {
  text-decoration: none;
}

// Radius
.radius_0 {
  border-radius: 0;
}

.radius_5 {
  border-radius: .5rem;
}

.radius_6 {
  border-radius: .6rem;
}

.radius_10 {
  border-radius: 1rem;
}

.radius_20 {
  border-radius: 2rem;
}

.radius_rounded {
  border-radius: 100%;
}

// Display Properties
.d_block {
  display: block;
}

.d_inline_block {
  display: inline-block;
}

.d_none {
  display: none;
}

.d_flex {
  display: flex;
}

.d_inline_flex {
  display: inline-flex;
}

.align_items_center {
  align-items: center;
}

.align_items_end {
  align-items: flex-end;
}

.align_items_start {
  align-items: flex-start;
}

.justify_content_center {
  justify-content: center;
}

.justify_content_between {
  justify-content: space-between;
}

.justify_content_around {
  justify-content: space-around;
}

.justify_content_evenly {
  justify-content: space-evenly;
}

.justify_content_start {
  justify-content: flex-start;
}

.justify_content_end {
  justify-content: flex-end !important;
}

.d_flex_center {
  @extend .d_flex;
  @extend .align_items_center;
  @extend .justify_content_center;
}

.flex_direction_row_reverse {
  flex-direction: row-reverse
}

.flex_direction_column {
  flex-direction: column;
}

.nowrap {
  flex-wrap: nowrap;
}

.flex_wrap {
  flex-wrap: wrap;
}

.word_break {
  word-break: break-all;
}

.white_space_nowrap {
  white-space: nowrap;
}

.white_space_wrap {
  white-space: normal;
}

.row {
  @extend .d_flex;
  @extend .flex_wrap;
}

.text_capitalize_clinic {
  text-transform: capitalize !important;
}

// Unorderded List : UL/LI
.list_unstyled {
  list-style: none;
}

// Image
.img_object_center {
  object-fit: cover;
  object-position: center;
}

.img_fluid {
  max-width: 100%;
}

// Form
.form_group {
  @extend .position_relative;
  margin-bottom: 2rem;

  label {
    @extend .mb_05;
    color: $gray_label;
  }

  ::placeholder {
    font-size: 1.4rem;
    line-height: 2rem;
    color: $gray_place;
  }
}

.form_control {
  background-color: $gray_think  !important;
  border: 2.5px solid $gray_think_border  !important;
  border-radius: 1rem;
  min-height: 4.2rem;
  width: 100%;
  padding: .8rem 1.5rem;
  @extend .montserrat_regular;

  &.ant-input {
    border-radius: 10px;
  }
}

.form_group {
  textarea {
    background-color: $gray_think;
    border: 2.5px solid $gray_think_border;
    border-radius: 10px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px !important;
    letter-spacing: 0.3125px;
    color: #C4C4C4;
    color: #3e4a58;
    resize: none;
    font-family: 'montserrat_medium';

    &:focus {
      box-shadow: unset !important;
      border: 2.5px solid #1784E9;

      &:hover {
        border: 2.5px solid #1784E9 !important;
      }
    }

    &:hover {
      border: 2.5px solid #DEDEDE !important;
    }
  }
}

select {
  background-image: url("../images/arrow_down.svg") !important;
  background-repeat: no-repeat !important;
  background-position: calc(100% - 1rem) center !important;
  padding-right: 2.5rem !important;
  @extend .montserrat_regular;
  @extend .pl_4;
  @include appearance(none);
}

.checkbox {
  display: flex;
  align-items: center;

  &>[type="checkbox"] {
    position: absolute;
    height: 18px;
    width: 18px;
    z-index: 99;
    opacity: 0;
  }

  &>span {
    display: inline-block;
    width: 18px;
    height: 18px;
    border: 1px solid #6F88A6;
    position: relative;
    z-index: 1;
    background: #F0F0F0;
  }

  &>[type="checkbox"]:checked+span:before {
    content: '';
    width: 22px;
    height: 1px;
    background: #6F88A6;
    transform: rotate(45deg);
    position: absolute;
    top: 7px;
    left: -3px;
  }

  &>[type="checkbox"]:checked+span:after {
    content: '';
    width: 22px;
    height: 1px;
    background: #6F88A6;
    transform: rotate(135deg);
    position: absolute;
    top: 7px;
    left: -3px;
  }

  &>span+label {
    white-space: nowrap;
    width: calc(100% - 28px);
    margin-left: 10px;
    margin-bottom: 0 !important;
  }
}

.overly {
  background: linear-gradient(90deg, #FFFFFF 0%, #EEEEEE 42.58%, rgba(233, 233, 233, 0.93) 52.82%, rgba(230, 230, 230, 0.82) 56.78%, rgba(211, 211, 211, 0.01) 72.46%);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

[class*="dot_"] {
  height: 1rem;
  width: 1rem;
  @extend .d_inline_block;
  @extend .radius_rounded;
}

.dot_red {
  background-color: red;
  height: .8rem;
  width: .8rem;
}

.dot_blue {
  background-color: $blue_dot;
  height: .4rem;
  width: .4rem;
}

.dot_green {
  background-color: green;
}

.page_wrapper {
  @extend .d_flex;
  @extend .fullWidth;
}

.content_wrapper {
  width: calc(100% - 25rem);
  position: relative;
}

.heading_content {
  width: 100%;
  background: rgba(154, 176, 189, 0.19);
  @extend .d_flex;
  @extend .align_items_center;
  flex-wrap: wrap;
  padding: 14px 22px 14px 48px;

  &.post_header {
    padding: 13px 22px 12px 48px;
  }

  @media (max-width: 1180px) {
    padding: 11px 22px;

    &.post_header {
      padding: 13px 22px;
    }
  }

  .maim_block_content {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .cg_head_main_block {
    width: 100%;
  }

  .cg_head_main_block {

    .cg_content_block,
    .cg_head_form_block {
      width: auto;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }

    .cg_go_clear {
      margin: 5px 0;

      .cg_go_link {
        padding-right: 12px;
      }

      button {
        background: none;
        border: none;
      }
    }

    .cg_content_block {
      justify-content: space-between;
      margin: 0 0 8px 0;

      .cg_content_view {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        p.cg_content_txt {
          margin: 4px 20px 5px 0;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.3125px;
          text-transform: capitalize;
          color: #000000;
          @extend .montserrat_semibold;

          span {
            display: inline-block;
          }
        }
      }

      .advanced_view {
        margin: 4px 0;
        &.del_user_list{
          margin: 4px 0 10px 0;
        }
      }
    }

    .cg_add_new {
      flex-wrap: wrap;
    }
  }

}

.clinic-name {
  color: #3079EF !important;
  font-weight: 500 !important;
}

.disableButton {
  color: rgba(0, 0, 0, 0.5) !important;
  background: rgba(251, 251, 251, 0.5) !important;
}

.resend_color {
  color: lightgray;
}

.dot_circle_image {
  img {
    max-width: initial;
    max-height: initial;
    width: auto;
    height: auto;
  }
}

.show_clear {
  padding-right: 15px;
}

.input-field-number {

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
}

.contact-format {
  .ant-input-number-handler-wrap {
    width: 0 !important;
  }

  .form_control {
    border-radius: 1rem;
    min-height: 4.2rem;
    width: 100%;
    display: flex;
    align-items: center;
  }

  .ant-input-number:hover .ant-input-number-handler-wrap,
  .ant-input-number-focused .ant-input-number-handler-wrap {
    opacity: 0 !important;
  }

  .ant-input-number:focus,
  .ant-input-number-focused {
    border: 2.5px solid #1784E9 !important;
    box-shadow: none !important;
  }

  input {
    height: 100%;
    line-height: 20px;
    letter-spacing: 0.3125px;
    color: #3E4A58;
    font-family: "montserrat_medium";
    font-weight: 500;
  }
}

.errorField-item {
  padding-bottom: 0px !important;
}

.ant-picker-footer {
  display: none;
}

//.ant-picker-content .ant-picker-time-panel-column:nth-last-child(1){
//  display: none;
//}
.event-main-section {
  position: relative;

  .event-date-format {
    position: absolute;
    z-index: 8;
    background: #fff;
    border-radius: 2px;
    box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%);
    transition: margin 0.3s;
    text-align: center;
    width: 56px;
    height: 228px;
    overflow: auto;

    li {
      list-style-type: none;
      display: block;
      width: 100%;
      height: 28px;
      margin: 0;
      padding: 0;
      color: rgba(0, 0, 0, 0.85);
      line-height: 28px;
      border-radius: 0;
      cursor: pointer;
      transition: background 0.3s;
    }
  }
}

;

.activeClasses {
  background: #e6f7ff;
}

.org-main-event {
  display: flex;
  width: 50%;
  align-items: center;
  justify-content: flex-end;

  button {
    margin-left: 10px;
  }
}

.select_min {
  .ant-input:placeholder-shown {
    text-overflow: unset !important;
  }

  input {
    padding: 0 !important;

    &::placeholder {
      padding: 0 !important;
    }
  }
}

.bio_details {
  textarea {
    color: #3e4a58 !important;
    font-family: 'montserrat_medium';
  }

}

.physician-email-field {
  .ant-input-disabled {
    background: #E7E7E7 !important;
    border: 2.5px solid #DEDEDE !important;
    color: rgba(62, 74, 88, 0.7) !important;
    cursor: auto !important;
  }
}

.post-mile-inner-text {
  font-family: 'montserrat_regular';
  font-size: 11px;
}

.post-description-main {
  white-space: pre-wrap;
}

.remove_multi_data {
  background-color: rgba(208,19,28,255);
  border-color: rgba(208,19,28,255);
  border: none;
  color: white;
  padding: 4px 23px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  border-radius: 7px;
  cursor: pointer;
}

.show_Promoted_post{
  background: transparent;
  border: none;
  cursor: pointer;
  text-align: right;
  text-decoration-line: underline;
  color: #1784E9;
  font-family: montserrat_semibold;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.3125px;
  margin-right: 20px;
  &:hover{
    text-decoration-line: underline;
  }
  &.del_user_list{
    margin: 0px;
    padding: 0px;
  }
}