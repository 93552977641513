.signup_clinic{
  .ant-form-item{
    margin-bottom: 14px;
    .ant-form-item-control-input-content{
      .ant-input-affix-wrapper{
        background: #fff;
        border: 1.5px solid #DEDEDE !important;
        min-height: 4.2rem;
        width: 100%;
        padding: 0;
        border-radius: 1rem;
        position: relative;
        input{
          padding: 10px;
          border-radius: 1rem;
          line-height: unset;
          &:focus{
            border: none;
          }
        }
        .ant-input-suffix{
          position: absolute;
          right: 10px;
          top: 52%;
          transform: translate(0, -50%);
        }
        &:focus, &.ant-input-affix-wrapper-focused{
          box-shadow: none;
        }
      }
    }
  }
  .ant-form-item-has-error :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover,
  .ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover,
  .ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input{
    border-right-width: 1px !important;
  }
  .ant-form-item-label  {
    padding-bottom: 0 !important;
    label {
      margin-bottom: 0.5rem;
      display: block;
      font-weight: 600;
      color: #595959;
      font-family: 'montserrat_regular';
      padding-bottom: 5px;
    }
  }
}
.ant-input-filed{
  .ant-form-item-control-input-content{
    input{
      // background: #F8F8F8 !important;
      border: 1.5px solid #DEDEDE !important;
      min-height: 4.2rem;
      width: 100%;
      padding: 0.8rem 1.5rem;
      border-radius: 1rem;
    }
  }
}


