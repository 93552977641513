.sign_in {
  @extend .position_relative;
  background: url(../images/signin_banner.jpg) no-repeat;
  background-position: center;
  background-size: cover;
  min-height: 985px;
  padding: 92px 40px 0;
  height: calc(100vh - 140px);

  @media (max-width: 991px) {
    min-height: 890px;
  }

  @media (max-width: 600px) {
    height: calc(100vh - 94px);
    padding: 50px 20px 0;
  }

  .sign_in_banner {
    display: flex;
    justify-content: space-between;
    max-width: 1452px;
    width: 100%;
    margin: 0 auto;

    @media (max-width: 1024px) {
      flex-direction: column;
    }
  }

  .cg_signBox_container {
    padding-left: 20px;
    max-width: 554px;
    width: 100%;
    margin-top: 214px;

    @media (max-width: 1024px) {
      margin: 0 0 0 auto;
      padding-left: 0;
    }
  }

  .header_logo {
    padding-bottom: 0;

    @media (max-width: 1024px) {
      padding-bottom: 50px;
    }


    img {
      width: 100%;
      height: auto;
      display: block;
      max-width: 375px;
      max-height: 270px;
      object-fit: contain;

      @media (max-width: 1024px) {
        max-width: 275px;
        max-height: 170px;
      }

      @media (max-width: 480px) {
        margin: 0 auto;
      }
    }

  }

  .sign_box {
    width: 100%;
    overflow: hidden;
    box-shadow: 0px 0px 20px rgba(214, 214, 214, 0.5);
    margin: 0 0 0 auto;
    border-radius: 6px;

    @media (max-width: 1024px) {
      margin: 0 auto;
      max-width: 500px;
    }

    .account_system {
      background-color: $dark_blue;
      padding: 1.6rem 6.4rem;

      @media (max-width: 991px) {
        padding: 1.6rem 2.4rem;
      }

      h4 {
        font-size: 20px;
        letter-spacing: 0.7px;
        text-transform: uppercase;
        color: #FFFFFF;
        margin: 0;

        @media (max-width: 1024px) {
          font-size: 18px;
        }

        @media (max-width: 991px) {
          font-size: 16px;
          line-height: 24px;
        }
      }
    }

    .admin_sigin {
      padding: 33px 20px 50px;
      background: $white;

      @media (max-width: 767px) {
        padding: 25px 20px 30px;
      }

      .sub_admin-signin {
        @include width(437px);
        margin: 0 auto;
      }

      h2 {
        margin-bottom: 54px;

        @media (max-width: 991px) {
          margin-bottom: 20px;
          font-size: 25px;
        }
      }

      .ant-input {
        padding-right: 4rem;

        &:focus {
          background: #F8F8F8;
          border: 1.5px solid #DEDEDE;
        }

        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:active {
          -webkit-box-shadow: 0 0 0 30px #F8F8F8 inset !important;
        }
      }

      .form_group {
        margin-bottom: 14px;

        ::placeholder {
          color: $gray_place;
        }

        span {
          @extend .position_absolute;
          right: 1.5rem;
          top: 38px;
          @extend .cursor_pointer;
        }
      }
    }

    .btn_primary {
      min-width: 26.7rem;
    }

    input {
      font-weight: 400;
      font-family: montserrat_medium;
      color: #3E4A58 !important;
      background: #fff;

      &::placeholder {
        font-family: montserrat_medium;
        font-weight: 400;
      }
    }

    .form_buttons {
      margin: 52px 0 0;

      button {
        font-family: montserrat_medium;
        font-weight: 500;
      }

      @media (max-width: 600px) {
        margin: 30px 0 0 !important;

        button {
          min-width: unset;
          width: 50%;
        }
      }
    }
  }
}

.ant-form-item-explain-error {
  font-weight: 400;
  font-family: montserrat_medium;
  font-size: 12px;
  padding-top: 5px;
}