.comman_input_scss {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.3125px;
  color: #636363;
  font-family: montserrat_medium;
  font-weight: 500;
  box-sizing: border-box;

  &::placeholder {
    font-family: montserrat_medium;
    font-weight: 500;
    color: #C4C4C4;
  }
}
.clinics {
  width: 100%;
  height: 100%;
  .group_hashtag_tab{
    width: 100%;
    background-color: #ECF0F2;
    .suggest_hashTag_tab{
      font-family: montserrat_bold;
      font-size: 14px;
      line-height: 20px;
      font-weight: 700;
      letter-spacing: 0.3125px;
      text-transform: uppercase;
      color: $dark_gray;
      max-width: 360px;
      width : 100%;
      padding: 28px 39px 27px 53px;
      cursor: pointer;
      &:last-child{
        padding: 28px 77px 27px 20px;
      }
      &.active_suggest_hashTag_tab{
        background-color: $dark_blue;
        color : white;
      }
    }
  }
  .suggestive_hashtag_name{
    background-color: $gray_think;
    padding: 28px 0 24px 53px;
    span{
          font-family: montserrat_bold;
          font-size: 14px;
          line-height: 20px;
          font-weight: 700;
          letter-spacing: 0.3125px;
          color: $black;
          margin-right: 5px;
    }
  }
  & > div{
    height: 100%;
  }
  .table_wrapper {
    height: calc(100% - 75px);
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;

    .pagination-container {
      align-self: flex-end;
    }
  }


  
  .filter-drop-status {
    position: absolute;
    min-width: unset;
    background: #FFFFFF;
    box-shadow: 4px 4px 50px rgba(0, 0, 0, 0.15);
    border-radius: 20px;
    padding: 26px 24px 27px;
    z-index: 9;
    max-width: 228px;
    min-width: 228px;
    width: 100%;

    .drop_down_list.list_unstyled {
      margin: 0;

      .checkbox_number {
        padding: 7px 5px 6px;
        background: #457B9D;
        border-radius: 100%;
        max-width: 25px;
        width: 100%;
        height: 25px;
        margin-right: 16px;

        h4 {
          font-family: montserrat_medium;
          font-weight: 500;
          font-size: 12px;
          line-height: 12px;
          text-align: center;
          color: #FFFFFF;
          margin: 0;
        }
      }

      .stage_checkbox_field {
        position: relative;
        max-width: 18px;
        width: 100%;
        height: 18px;
        display: block;
        margin-right: 22px;
        border: 2px solid #457B9D;
        border-radius: 4px;

        input[type="checkbox"] {
          width: 100%;
          height: 100%;
          appearance: none;
          opacity: 0;
          position: absolute;
          z-index: 99;
          cursor: pointer;
        }

        .checkbox_clone {
          position: absolute;
          width: 100%;
          height: 100%;

          &::after {
            content: "";
            position: absolute;
            display: none;
          }
        }

        input[type="checkbox"]:checked+.checkbox_clone::after {
          display: block;
          left: 0;
          top: 0px;
          width: 6px;
          height: 11px;
          border: solid #457B9D;
          border-width: 0 2px 2px 0;
          transform: rotate(45deg);
          right: 0;
          margin: 0 auto;
        }
      }

      .status_checkbox {
        margin-bottom: 9px;

        &:last-child {
          margin: 0;
        }
      }
    }

    label {
      font-family: montserrat_medium;
      font-weight: 500;
      font-size: 16px;
      line-height: 12px;
      color: #595959;
      margin: 0;
    }
  }

}
.heading_content,.setting_header {
  @extend .justify_content_between;

  .form_group {
    @extend .position_relative;
    margin: 4px 5px 4px 0;
    &.cg_search_form{
      margin: 4px 27px 4px 0;
      @media (max-width: 1180px) {
        margin: 4px 15px 4px 0;
      }
      &.name_keyword{
        margin: 4px 14px 4px 0;
      }
      }
      .form_control {
        max-width: 310px !important;
        width: 100% !important;
        min-width: 310px;
        padding-left: 3.3rem;
        background-color: #FFFFFF;
        padding: 9px 30px 8px 32px;
        display: block;
        min-height: auto !important;
        @extend .radius_20;
        letter-spacing: 0.7px;
        @extend .comman_input_scss;
        border-width: 2px;

        @media (max-width: 1180px) {
          max-width: 230px !important;
          min-width: 230px;
          font-size: 12px;
          line-height: 18px;
          &::placeholder{
            font-size: 12px;
            line-height: 18px;
          }
        }
      }

      .org_cancle_icon{
        top: 12px;
        right: 7px;
        @extend .comman_cancle_button;
      }
      .serch_icon {
        width: 1.9rem;
        height: 1.9rem;
        @extend .position_absolute;
        top: 9px;
        left: 7px;
        @extend .cursor_pointer;

        img {
          width: 1.9rem;
          @extend .img_object_center;
        }
      }
    }

    .advanced_view {
      @extend .comman_font_style_;
      text-align: right;
      text-decoration-line: underline;
      color: #1784E9;

      // &.specialists_advanced_view {
      //   padding-right: 35px;
      // }
    }

    .add_clinics {
      padding: 5px 0 4px 5px;
    }

    button.btn.add_specialist {
      @extend .comman_font_style_;
      padding: 10px 16px !important;
      border: 1px solid #3e4a58;

      &:hover {
        background: transparent;
        color: #3e4a58;
      }
    }

    .specialists_link_wrap{
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      @include width(384px);
      .archived_events{
        @extend .comman_font_style_;
        text-decoration-line: underline;
        color: #1784E9;
        display: block;
        padding: 5px 10px;
      }
    }
    .specialists_link_block {
      padding: 4px 0 4px 5px;
      &.add_new_block{
        max-width: 156px;
        width: 100%;
        .add_specialist{
          width: 100%;
          svg{
            display: inline-block;
            vertical-align: middle;
            margin-right: 8px;
            transition: all 0.3s ease-in-out;
          }
          &:hover{
            svg{
              path{
                fill: #3e4a58;
              }
            }
          }
        }
      }
    }
    &.addnew_phys_header, &.addnew_org_header{
      padding: 28px 24px 27px 48px;
      @media screen and (max-width: 991px) {
        padding: 28px 24px;
      }
      .cg_content_block{
        margin: 0;
        h3{
          margin: 0;
          @extend .comman_font_style_;
          color: #000000;
        }
      }
    } 
    &.addnew_org_header{  
      padding: 11px 94px 10px 48px;
      &.addNew_event_header{
          padding: 24px 94px 21px 48px;
      }
      @media screen and (max-width: 1180px) {
        padding: 11px 24px 10px 48px;
        &.addNew_event_header{
          padding: 24px 24px 21px 48px;
        }
      }
      @media screen and (max-width: 1024px) {
        padding: 12px 24px;
        &.addNew_event_header{
          padding: 24px 24px 21px;
        }
      }
      .cg_head_main_block{
        @include flex;
        flex-wrap: wrap;
      }
      .cg_content_block{
        @include width(525px);
        margin-right: 20px;
        @media screen and (max-width: 1180px) {
          max-width: 540px;
        }
        @media screen and (max-width: 1024px) {
          max-width: 450px;
        }
        @media screen and (max-width: 991px) {
          max-width: 370px;
        }
        .add_event_block{
          display: flex;
          flex-wrap: wrap;
          align-items: flex-start;
          padding-right: 20px;
          @include width(400px);
          h3{
            margin-right: 10px;
            width: auto;
          }
          span{
            @extend .comman_font_style_;
            color: #595959;
            display: block;
            @include width(250px);
            @media (max-width: 1024px) {
                font-size: 12px !important;
            }
          }
        }
        .org_steps_main{
          display: flex;
          align-items: center;
          margin: 10px 0 5px;
          span{
            font-family: montserrat_medium;
            font-weight: 500;
            font-size: 16px;
            line-height: 12px;
            text-align: center;
            color: #FFFFFF;
            width: 39px;
            height: 39px;
            border-radius: 100%;
            background-color: rgba(69, 123, 157, 0.5);
            display: grid;
            place-items: center;
            cursor: pointer;
            &:first-child{
              margin-right: 18px;
            }
            &.active{
              background-color: #457B9D;
            }
          }
        }
      }
      .org_back{
        text-align: right;
        text-decoration-line: underline;
        @extend .comman_font_style_;
        margin: 5px 0;
      }
    }
}
.spe_managePost_main{
  .heading_content {
    padding: 12px 94px 11px 48px;
    @media screen and (max-width: 1500px) {
      padding: 12px 20px 11px 48px;
    }
    @media screen and (max-width: 991px) {
      padding: 12px 20px 11px;
    }
    .cg_head_form_block{
      padding: 0 15px 0 0;
    }
    .specialists_user_prof {
      display: flex;
      align-items: center;
      padding: 6px 0;
      @media screen and (max-width: 1180px) {
        padding: 7px 0;
      }
        span{
          font-family: montserrat_semibold;
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
          text-align: right;
          letter-spacing: 0.3125px;
          color: #3E4A58;
          margin-right: 18px;
        }
        img.cg_spe_profile {
          width: 40px;
          height: 40px;
          border: 2.5px solid #FFFFFF;
          filter: drop-shadow(0px 4px 25px rgba(0, 0, 0, 0.3));
          border-radius: 100%;
          display: block
        }
    }
  }
}

.comman_font_style_{
  font-family: montserrat_semibold;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.3125px;
}


.addNew_org_first{
  .ant-form{  
    padding: 36px 20px 40px 54px;
    @include width(1080px);
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    @media screen and (max-width: 1024px) {
      padding: 36px 24px;
    }
    @media screen and (max-width: 991px) {
      flex-direction: column;
    }

    .addNew_org_detail{
      @include width(480px);
      padding-right: 40px;
      @media screen and (max-width: 991px) {
        margin: 0 auto;
        padding-right: 22px;
      }
      &.addNew_eventorg_detail{
        @include width(510px);
        padding-right: 70px;
        @media (max-width: 991px) {
          @include width(590px);
          padding-right: 140px;
        }
        .support_org_field{
          position: relative;
          margin: 0;
          .ant-form-item{
            margin: 0;
          }
          .ant-select-clear{
            opacity: 1 !important;
            top: 27%;
            width: 20px;
            height: 20px;
            svg{
              color: #495568;
              background: transparent;
              width: 20px;
              height: 20px;
            }
          }
          .fill{
            &:after{
              top: 42px !important;
            }
          }
          input{
            font-family: 'montserrat_medium';
            padding-right: 40px;
            font-weight: 400;
          }
          .support_org_notif {
            position: absolute;
            top: 32px;
            right: -50px;
            cursor: pointer;
            .note_txt_wrap {
              position: absolute;
              top: -55px;
              min-width: 134px;
              width: 134px;
              font-weight: 400;
              font-size: 10px;
              line-height: 12px;
              letter-spacing: 0.3125px;
              color: #FFFFFF;
              font-family: 'montserrat_regular';
              background: #5D737E;
              border-radius: 5px;
              padding: 6px 8px 5px 8px;
              left: -20px;
              display: block;
              opacity: 0;
              transition: 0.5s all ease-in-out;
              &::after{
                content: '';
                position: absolute;
                border-top: 5px solid #5d737e;
                border-left: 5px solid #5d737e;
                bottom: -5px;
                width: 10px;
                height: 10px;
                transform: rotate(225deg);
                left: 26px;
              }
            }
            &:hover .note_txt_wrap{
              opacity: 1;
            }

          }
          span.org_cancle_icon {
            right: 10px;
            top: 36px;
            @extend .comman_cancle_button;
          }
           //.ant-select-selection-item{
           //  color: unset !important;
           //}
          .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input){
            .ant-select-selector{
              box-shadow: none !important;
            }
          }
          .ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) .ant-select-selector{
            margin-bottom: 0;
            border: 2.5px solid #DEDEDE !important;
          }
          #support_org_help{
            .ant-form-item-explain-error{
              margin-bottom: 5px;
            }
          }
          .ant-select-single.ant-select-show-arrow .ant-select-selection-item, .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder{
            line-height: 34px !important;
            padding-right: 0 !important;
            padding-left: 12px !important;
            //letter-spacing: 0.3125px;
            //color: #3E4A58;
            font-family: 'montserrat_medium';
            font-weight: 500;
          }
          .ant-select-selector{
            //border: none;
            min-height: 4.2rem;
            border: 2.5px solid #DEDEDE !important;
            background-color: #F8F8F8 !important;
            border-radius: 10px !important;
            width: 100% !important;
            display: block !important;
            padding: 0;
            position: relative;
            height: auto;
            appearance: none;
            margin-bottom: 24px;
            &:focus{
              box-shadow: none !important;
            }
            &:after{
              display: none;
            }

            //.ant-select-selection-search{
            //  position: unset;
            //  display: block;
            //
            //  input{
            //    background-color: #F8F8F8 !important;
            //    border: 2.5px solid #DEDEDE !important;
            //    min-height: 4.2rem;
            //    border-radius: 10px !important;
            //    opacity: 1 !important;
            //    &:focus{
            //      border: 2.5px solid #1784E9 !important;
            //    }
            //  }
            //}
            //.ant-select-selection-item{
            //  position: absolute;
            //  top: 12px;
            //  left: 15px;
            //}
          }
        }
     
      }
      .datepicker_field{
        display: flex;
        flex-wrap: wrap;
        margin: 0;
        .ant-form-item{
          &.date_lable{
            width: 100%;
            margin: 0;
            .ant-form-item-control{
              display: none;
            }
          }
          &.select_date{
            @include width(120px);
            margin-right: 16px;
          }
          &.select_hours{
            position: relative;
            &::after {
              position: absolute;
              content: ':';
              right: -10px;
              top: 9px;
              color: #595959;
              font-size: 20px;
              line-height: 20px;
            }
          }
          &.select_hours, &.select_min, &.select_quarter{
            @include width(48px);
          }
          &.select_date, &.select_hours, &.select_min, &.select_quarter {
            input, .ant-select-selection-item{
              @extend .comman_font_style_;
              font-family: 'montserrat_medium';
              font-weight: 500;
              color: #3E4A58;
              text-align: center;
              &::placeholder{
                font-family: 'montserrat_medium';
                font-weight: 500;
              }
            }
            .ant-picker, .ant-select-selector{
              background: #F8F8F8;
              border: 2.5px solid #DEDEDE;
              border-radius: 10px;
              padding: 9px 10px 8px;
            }
            .ant-select-selector{
              padding: 0;
              height: 42px;
              .ant-select-selection-item{
                padding: 0;
                @include flex_center;
              }
            }
            .ant-picker-suffix{
              display: none;
            }
          }
          &.select_hours, &.select_min{
            margin-right: 16px; 
            .ant-picker{
              padding: 9px 5px 8px;
            }
          }
        }
      }
      .form_group{
        textarea{
          &:hover{
            border: 2.5px solid #DEDEDE !important;
          }
          &:focus{
            border: 2.5px solid #1784E9 !important;
          }
        }
      }
    }
    .ant-form-item-label{
      line-height: 20px;
      padding: 0 0 6px;
      label {
        @media (max-width: 1000px) {
          font-size: 12px;
        }
      }
    }
    textarea{
      height: 310px;
    }
    .org_buttons{
      @include width(324px);
      margin: 45px auto 0;
      button {
        font-family: 'montserrat_medium';
        font-weight: 500;
        width: 100%;
        margin: 0;
        min-width: unset;
        &:first-child{
          margin-right: 16px;
        }
      }
    }
    .ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input{
      border-right-width: 2.5px !important;
    }
    //.support_org_field .ant-select-selection-item
    input.form_control, textarea, select {
      line-height: 20px;
      letter-spacing: 0.3125px;
      color: #3E4A58;
      font-family: 'montserrat_medium';
      font-weight: 400;
      &::placeholder{
        font-family: 'montserrat_medium';
        font-weight: 500;
      }
    }
    .ant-select-in-form-item{
    

      .ant-select-arrow{
        display: none
      }
    }
    .addnew_org_images {
      @include width(474px);
      margin: 30px 0 0;
      padding-left: 20px;
      @media screen and (max-width: 991px) {
        margin: 40px auto 0;
        padding-left: 0;
      }
      .org_upload_btn_main{
        span{
          @extend .comman_org_font_style;
          display: inline-block;
          margin-left: 7px;
          cursor: pointer;
        }
      }
      .ant-upload-list-item-thumbnail{
        opacity: 1;
      }
    }
    .add_comp_logo_main{
      margin-top: 28px;
      h4{
        @extend .comman_org_font_style;
        display: block;
        text-align: left;
        text-transform: capitalize;
        margin-bottom: 18px;
      }

      .upload-list-inline{
        display: block;
        position: relative;
        .ant-upload{
            span{
              width: 100%;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;
            }
        }
        .ant-upload-select-picture{
          background: #D9D9D9;
          @include width(100px);
          height: 100px;
          border-radius: 10px;
          // padding: 9px 10px;
          display: grid;
          place-items: center;
          .org_upload_btn{
            padding: 0;
            border: none;
            background-color: unset;
            img{
              width: 24px;
              height: 24px;
            }
          }
        }
        .ant-upload-list-picture{
          margin-top: 0 !important;
          .ant-upload-list-picture-container{
            width: 100px;
            height: 100px;
            top: 0;
            position: absolute;
          }
          .ant-upload-list-item-name {
            display: none;
          }
          .ant-upload-list-item{
            padding: 0;
            border: none;
            margin: 0;
            height: 100px;
          }
          .ant-upload-list-item-thumbnail{
            width: 100px;
            height: 100px;
            opacity: 1;
            img{
              width: 100px;
              height: 100px;
              object-fit: cover;
              border-radius: 10px;
            }
          }
        }


      }
    }
  }
}

.ant-form{
  .ant-input.post_detail, .ant-input.post_title{
    &:hover{
      border: none !important;    
    }
    &:focus{
      border: none !important;
    }
  }

  .ant-form-item-explain-error{
    line-height: 11px;
    font-family: ProximaNova_Medium;
  }
}
.addPost_modal_main{
  .ant-form{
    .ant-form-item-explain-error{
      font-family: 'montserrat_medium';
    }
  }
}

.comman_org_font_style{
  font-family: 'montserrat_medium';
  font-size: 14px;
  line-height: 20px; 
  letter-spacing: 0.3125px;
  color: #595959;
}

.comman_cancle_button{
  position: absolute;
  width: 20px;
  height: 20px;
  display: block;
  cursor: pointer;
  img{
    display: block;
  }
}
select[name="years_of_experiance"] {
  padding-left: 23px;
  max-width: 110px;
  width: 100% !important;
}
.invitation_name{
  font-weight: 600;
  text-transform: capitalize;
}

.blue-color{
  color: #1784E9 !important;
}