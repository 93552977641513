.delete-pop  {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .title {
    margin: 15px;
    font-size: 22px;
  }

  .desc {
    color: #494949;
    line-height: 1.7rem;
    text-align: center;
    margin-bottom: 20px;
    font-family: 'montserrat_regular';
    // text-transform: capitalize;
  }

  .action {
    @extend .d_flex;
    @extend .justify_content_between;
    @extend .align_items_center;

    .btn_primary {
      background: #C00C2D;

    }
  }
}

.modal.editClinic {
  width: 78.6rem !important;
  top: 58px;
}

.edit-clinic-modal {
  width: calc(100% - 0rem);

  .hs_comman_modal_block {
    max-width: 100%;
    max-height: 70vh;
    overflow-y: scroll;
    @include scrollbar;
  }
}

.filter-drop-status {
  position: absolute;
  min-width: 18.4rem;
  background: #ffffff;
  top: 90%;
  padding: 2.5rem 2rem;
  box-shadow: 0 0 2.5rem rgb(0 0 0 / 15%);
  z-index: 9;
}

.ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  content: " " !important;
}

.ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.ant-form-item-has-error :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
.ant-form-item-has-error :not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper,
.ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover,
.ant-form-item-has-error :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover,
.ant-form-item-has-error :not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper:hover {
  background-color: #F8F8F8 !important;
  border-color: #d9d9d9 !important;
}

.ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.ant-form-item-has-error :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
.ant-form-item-has-error :not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper,
.ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover,
.ant-form-item-has-error :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover,
.ant-form-item-has-error :not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper:hover {
  box-shadow: none !important;
  border-right-width: 0px !important;
  outline: 0;
}
.ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).ant-input{
  border-right-width: 2px !important
}

.main_additionalmin-block {
  h4 {
    font-family: montserrat_bold;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #000000;
    text-align: left;
    text-transform: unset;
    padding-bottom: 20px;
  }

  &.invoice-block {
    @include width(640px);
    padding: 20px;
    margin: 0 auto;

    // .address{
    //   max-width: 350px;
    // }
    .sub_additionalmin-block {
      max-height: unset;
    }
  }

  h2 {
    text-align: center;
    font-size: 17px;
    padding: 0 0 10px;
    text-transform: capitalize;
  }

  .sub_additionalmin-block {
    padding: 0 10px;
    overflow: auto;
    min-height: auto;
    max-height: 60vh;

    &::-webkit-scrollbar {
      width: 3px;
    }

    &::-webkit-scrollbar-thumb {
      background: #BFC6CF;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    .total_plan {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      padding: 22px 0 0;

      span {
        font-family: montserrat_semibold !important;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        color: #000000;

        &:first-child {
          padding-right: 10px;
        }
      }

    }

    .total_cost {
      @include width(250px);
      display: block;
      margin-left: auto;
      padding: 50px 0 0;

      .sub_total-cost {
        display: flex;
        justify-content: space-between;
        padding-top: 22px;
        border-top: 1px solid #7E7E7E;

        span {
          font-family: montserrat_bold;
          font-weight: 700;
          font-size: 14px;
          line-height: 17px;
          color: #000000;
        }
      }
    }
  }

  .client_corporation_block {
    padding-bottom: 30px;

    h6 {
      color: #000;
      text-align: left;
      font-size: 16px;
      text-transform: capitalize;
      padding-bottom: 4px;
      line-height: 20px;
      display: block;
      width: 100%;
      font-family: montserrat_bold;
      font-weight: 700;
    }

    .address {
      font-family: montserrat_semibold;
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      color: #000000;
      display: block;
      padding-bottom: 20px;
    }

    .physician_licenses-block {
      padding: 0;

      span {
        font-family: montserrat_semibold;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        color: #000000;
        display: block;
        padding: 0;
        width: auto;

        &:first-child {
          padding-bottom: 4px;
        }
      }
    }

    .billing_cycle-block {
      padding-top: 20px;

      h4 {
        font-family: montserrat_medium;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        color: #000000;
        display: block;
        width: 100%;
        text-align: center;
        background: #EBF3F8;
        padding: 6px 10px 7px;
      }
    }
  }
}

.ant-modal.modal.additionalMinutes {
  max-width: 780px;

  .ant-modal-body {
    padding: 23px 10px 31px !important;
  }
}

.additionalmin_comman_style {
  color: #000;
  font-size: 12px;
  font-family: montserrat_medium;
  font-weight: 500;
  display: block;
}

.invoice_header-top {
  flex-wrap: unset !important;

  .invoice-download-icon {
    width: 100%;
    justify-content: flex-end;
    display: flex;
  }

  img {
    cursor: pointer;
  }
}

.layout.invoice {
  .page_wrapper {
    display: block;

    .main_additionalmin-block {
      min-height: calc(100vh - 146px);
      //height: 100%;
    }

    footer {
      .gradiant_border {
        margin: 0 auto;
        @include width(520px);
        height: 1px;
        background: radial-gradient(50% 50% at 50% 50%, #7C7C7C 0%, rgba(196, 196, 196, 0) 100%);
      }

      .sub-footer_block {
        padding: 12px 10px 20px;

        .comman_style {
          font-family: montserrat_medium;
          font-weight: 500;
          font-size: 9px;
          line-height: 11px;
          text-align: center;
          letter-spacing: 0.05em;
          color: #7C7C7C;
        }

        p {
          @extend .comman_style;
          @include width(410px);
          margin: 0 auto;
          padding-bottom: 12px;
        }

        span {
          @extend .comman_style;
          display: block;
        }
      }
    }

    header {
      .main_herader_row {
        @include width(640px);
        margin: 0 auto;
        background: radial-gradient(50% 3497.52% at 50% 50%, rgba(206, 213, 218, 0.27) 0%, rgba(206, 213, 218, 0) 100%);
        padding: 9px 10px 8px;

        .header {
          display: block;

          .logo {
            margin: 0 auto;
            @include width(164px);
            height: 51px;
          }
        }

      }
    }
  }
}

.ant-modal.flagPopup, .ant-modal.FlagPhyPopup {
  max-width: 410px;
  width: 100%;
  .ant-modal-body{
    padding: 22px 20px 40px !important;
  }
}
.cg_flag_popup_main{
  // padding: 20px 30px;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  z-index: 99;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  overflow: auto;
  .cg_flag_inner {
      width: 100%;
      margin: 0 auto;
      background: #FFFFFF;
      position: relative;
      box-sizing: border-box;
      .cg_close_btn{
        display: block;
        text-align: right;
        cursor: pointer;
        font-size: 0;
      }
      h2{
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        text-align: center;
        text-transform: capitalize;
        color: #000000;
        font-family: 'montserrat_semibold';
        margin: 2px 0 30px;
        letter-spacing: 0;
      }
      .cg_setFlag_block {
        @include flex;
        max-width: 121px;
        width: 100%;
        margin: 0 auto 25px;
        position: relative;
        &::after{
          content: '';
            position: absolute;
            width: 100%;
            height: 1px;
            background-color: #457B9D;
            z-index: 1;
        }

        span{
          max-width: 39px;
          width: 100%;
          height: 39px;
          background-color: #CED5DA;
          border-radius: 100%;
          display: grid;
          place-items: center;
          font-weight: 500;
          font-size: 16px;
          line-height: 11px;
          text-align: center;
          color: #000000;
          font-family: 'montserrat_medium';
          z-index: 9;
          padding: 2px 0 0 0;
          position: relative;
          input{
            cursor: pointer;
            font-size: 0;
            height: 100%;
            left: 0;
            opacity: 0;
            padding-left: 26px;
            position: absolute;
            top: 0;
            width: 100%;
          }
        };
        .flagActive{
          color: #ffffff;
          background-color: #C00C2D;
        }
      }

    }
  }

  .cg_radiobtn_block {
    @include flex_center;
    .cg_radio_field{
      margin: 5px 11px;
    }
      label {
        display: inline-block;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        text-transform: capitalize;
        color: #457B9D;
        font-family: 'montserrat_semibold';
        input{
          display: inline-block;
          vertical-align: middle;
          accent-color: black;
          margin: 0 8px 3px 0;
          cursor: pointer;
        }
    }
  }
  .cg_reset_apply_block {
    max-width: 324px;
    width: 100%;
    margin: 28px auto 0;
    display: flex;
    justify-content: center;
    .reset_apply_btn {
      width: calc(50% - 8px);
      margin-right: 16px;
      padding: 11px 10px;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      letter-spacing: 0.3125px;
      border: none;
      border-radius: 10px;
      font-family: "montserrat_medium";
      &:last-child{
        margin: 0;
      }
      &.reset{
        color: #868686;
        background-color: #E8EAF0;
      }
      &.apply{
        color: $white;
        background-color: $maroon;
      }
  }
  }

  .ant-modal.modal.calenderFilterPopup , .ant-modal.modal.AddedOnFilterPopup,
  .ant-modal.modal.DatePostedFilterPopup, .ant-modal.modal.LastLoggedOnPhyPopup,
  .ant-modal.modal.AddedOnDatePhyPopup, .ant-modal.modal.AddOnOrgDatePopup,
  .ant-modal.modal.EventStartDateFilterPopup,   .ant-modal.modal.EventEndDateFilterPopup,
  .ant-modal.modal.ArchivedStartDateFilter,.ant-modal.modal.ArchivedEndDateFilter,
  .ant-modal.modal.StartDateEventByORGFilterDatePop,   .ant-modal.modal.EndDateEventByORGFilterDatePop , .ant-modal.modal.datePostManageFilter
  {
    max-width: 670px;
    width: 100% !important;
    .rdrDateRangePickerWrapper{
      width: 100%;
      .rdrDefinedRangesWrapper{
        display: none;
      }
      .rdrCalendarWrapper.rdrDateRangeWrapper{
        width: 100%;
      }
    }

  }

  .calenderFilterPopup, .AddedOnFilterPopup,.DatePostedFilterPopup,
  .LastLoggedOnPhyPopup, .AddedOnDatePhyPopup,.AddOnOrgDatePopup,.EventStartDateFilterPopup,.EventEndDateFilterPopup,
  .ArchivedStartDateFilter, .ArchivedEndDateFilter, .StartDateEventByORGFilterDatePop, .EndDateEventByORGFilterDatePop ,.datePostManageFilter{
    .ant-modal-content{
      max-height: 610px;
      //overflow-y: auto;
    }
    .ant-modal-body{
      padding: 37px 30px 52px !important;
      @media (max-width: 991px) {
        padding: 37px 20px 52px !important;
      }
      .calender_modal{

          &.mac{
            .rdrMonth{
              .rdrWeekDay{
                font-family: 'Gotham' !important;
              }
              .rdrDays{
                .rdrDayNumber{
                  font-family: 'Gotham' !important;
                }
              }
            }
          }

        .cg_radiobtn_block{
          margin-top: 14px;
        }
        .cg_reset_apply_block{
          .reset_apply_btn.reset{
            color: #495568;
          }
        }
        h2{
          font-family: 'montserrat_semibold';
          font-weight: 600;
          font-size: 16px;
          line-height: 20px;
          text-align: center;
          text-transform: capitalize;
          color: #25282C;
        }
        .rdrDateRangeWrapper{
          position: relative;   
          .rdrMonthAndYearWrapper{
              height: 20px;
              position: absolute;
              padding: 0;
              width: 100%;
              top: 12px;
            .rdrMonthAndYearPickers{
              display: none;
            }
            .rdrNextPrevButton{
              background-color: unset;
              position: absolute;
              width: 14px;
              height: 14px;
              margin: 0;
              &::after{
                content: '';
                position: absolute;
                background: url(../images/prev_calendar_icon.svg) no-repeat;
                background-size: contain;
                width: 7px;
                height: 10px;
                left: 0;
                top: 0;
              }
              i{
                border-width: 0;
                border-color: unset;
              }
              &.rdrNextButton{
                &::after{
                  transform: rotate(180deg);
                  left: unset;
                  right: 0;
                }
              }
            }
            .rdrPprevButton{
              left: 0;
            }
            .rdrNextButton{
              right: 0;
            }
          }
        }
        .rdrMonth{
          padding: 0;
          width: 50%;
          &:first-child{
            padding: 0 15px 0 0;
          }
          &:last-child{
            padding: 0 0 0 15px;
          }
          .rdrWeekDays{
            padding: 0 0 9px;  
          }
          .rdrMonthName {
            font-family: raleway_bold;
            font-weight: 700;
            text-align: center;
            font-size: 16px;
            line-height: 19px;
            text-align: center;
            color: #000000;
            padding: 10px 10px 22px;
          }
          .rdrWeekDay{
            font-size: 10px;
            line-height: 12px;
            text-align: center;
            text-transform: uppercase;
            font-family: 'Gotham';
            font-weight: 500;               
            color: rgba(23, 132, 233, 1);
          }
          .rdrDays{
            .rdrDayNumber{
              font-size: 13px;
              line-height: 16px;
              text-align: center;
              color: #000000;
              font-family: 'Gotham';
              font-weight: 400;
            }
            .rdrDay{
              height: 45px;
              @extend .comman_datePicker_style;
              &.rdrDayToday{
                span.rdrStartEdge.rdrEndEdge {
                 @extend .comman_date_selected_style;
                }
                .rdrDayNumber{
                  span{
                    &::after{
                      display: none;
                    }
                  }
                }
               
              }
              &.rdrDayHovered{
                @extend .comman_datePicker_style;
              }
              span.rdrStartEdge.rdrEndEdge {
                  @extend  .comman_date_selected_style;
              }
              .rdrStartEdge, .rdrInRange, .rdrEndEdge{
                color: #457B9D !important;
              }
            }
          }
        }
        .datepicket_from_to{
          display: flex;
          margin: 22px 0 10px;
          span{
            width: 50%;
            text-align: center;
            font-weight: 600;
            font-family: 'montserrat_semibold';
            font-size: 16px;
            line-height: 20px;
            text-align: center;
            text-transform: uppercase;
            color: #457B9D;
          }
        }
       
      }
    } 
  }

  .comman_date_selected_style{
    background-color: #457B9D;
    width: 32px;
    height: 32px;
    border-radius: 100%;
    top: 50%;
    left: 50%;
    right: 0;
    bottom: 0;
    transform: translate(-50%, -50%);
  }
  .comman_datePicker_style{
    .rdrDayStartPreview, .rdrDayEndPreview{
      @extend .comman_date_selected_style;
      border: none !important;
      z-index: 1;
    }
    .rdrDayStartPreview + span.rdrDayNumber span, .rdrDayEndPreview + span.rdrDayNumber span{
      color: $white !important;
      position: relative;
      z-index: 9;
    }
  }
  .add-physician-field{
    .avatar-uploader-details{
      .ant-upload-list-item-info{
        &:before{
          content: none !important;
        }
      }
      .ant-upload-list-picture .ant-upload-list-item-thumbnail, .ant-upload-list-picture-card .ant-upload-list-item-thumbnail{
        opacity: 1 !important;
      }
      .ant-upload-select-picture-card{
        border: none !important;
        .ant-upload{
          display: none;
        }
        //display: none;
      }
    }
  }
.email-auto-complete-popup{
  .ant-select-selector{
    background-color: #f8f8f8!important;
    border: 2.5px solid #dedede!important;
    min-height: 4.2rem;
    border-radius: 10px !important;
  }
  .ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) .ant-select-selector{
    border: 2.5px solid #dedede!important;
    box-shadow: none !important;
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input{
    height: 36px ;
  }
  .ant-select-selection-search{
    input{
      color: #3e4a58;
      font-family: montserrat_medium;
      font-weight: 400;
      letter-spacing: .3125px;
      line-height: 20px;
    }
  }
}