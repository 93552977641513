.main_setting {
  margin: 10px;

  .setting_form {
    max-width: 460px;
    width: 100%;
    margin: 100px auto 0;
    padding: 30px;
    box-shadow: 0px 0px 20px rgb(214 214 214 / 50%);
    border-radius: 6px;

    .ant-form-item-label {
      padding-bottom: 4px;
    }

    label {
      display: block;
      font-weight: 600;
      color: #595959;
      @extend .montserrat_regular;
    }

    input {
      padding: 5px !important;
      background-color: unset;
    }

    span.ant-input-affix-wrapper {
      background: #F8F8F8;
      border: none !important;
      min-height: 4.2rem;
      width: 100%;
      border-radius: 1rem;
    }

    .ant-form-item-control-input-content {
      border: 1.5px solid #DEDEDE;
      border-radius: 1rem;
    }

    .setting_btn_block {
      margin: 0 !important;
      padding: 10px 0 0 0;
      display: block;

      .ant-form-item-control-input-content {
        border: none;
        display: flex;
        justify-content: center;

        button.ant-btn.ant-btn-primary {
          width: 49%;
          line-height: 2rem;
          transition: all 0.3s ease-in-out;
          font-weight: 600;
          @extend .montserrat_regular;
          letter-spacing: 0.03rem;
          font-size: 1.4rem;
          height: unset !important;
          border: none;
          border-radius: 1rem;
          padding: 1.1rem 2.5rem;

        }

        .clear {
          color: #868686;
          background: #E8EAF0;
          margin-right: 5px;
        }

        .submit {
          background: #C00C2D;
          color: #ffffff;
          margin-left: 5px;
        }

        @media (max-width: 600px) {
          & {
            flex-wrap: wrap;

            button.ant-btn.ant-btn-primary {
              width: 100%;
            }

            .clear,
            .submit {
              margin: 5px 0
            }
          }
        }
      }
    }

    .ant-form-item-explain-error {
      padding-top: 2px;
    }

    .ant-form-item {
      margin-bottom: 16px;
    }
  }
}

.ant-form-item-explain-error {
  font-family: montserrat_semibold;
  font-weight: 500;
  font-size: 11px;
  line-height: 12px;
  letter-spacing: 0.2px;
  color: #C00C2D !important;
  padding-top: 5px;
}

.ant-form-item-explain {
  min-height: unset;
}
.clinics{
  .main_setting_block{
    width: 100%;
    .specialists_link_block {
      align-items: center;
      display: flex;
      margin-right: 31px;
      max-width: 214px;
      width: 100%;
      margin-left: auto;
      &.hash_group{
        max-width: 241px;
        width: 100%;
      }
      .add_specialist{
        display: flex;
        align-items: center;
        justify-content: center;
        svg{
          margin-right: 8px;
        }
      }
    }
  }

}

.main_setting_block {
  width: calc(100% - 25rem);
  position: relative;

  .setting_header {
    width: 100%;
    background-color: rgba(154, 176, 189, 0.19);
    display: flex;
    justify-content: unset;
    align-items: center;
    .header_tab{
      display: flex;
      margin-right: 20px;
      .header_tabDiv {
        font-family: montserrat_bold;
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.3125px;
        text-transform: uppercase;
        margin-right: 10px;
        // padding: 5px 30px;
        padding: 28px 50px 27px 50px;
        color: #5D737E;

      }
      .selectedDiv{
        color: white;
        background: #457B9D;
      }
    }
    .cg_go_link{
      border: unset;
      background-color: transparent;
    }
  }

  .setting_fields_block {
    padding: 20px 40px;

    .maintenance_mode_block {
      margin-bottom: 30px;

      .switch_btn_block {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        @include width(240px);
        margin-top: 20px;
      }
    }

    .android_build {
      .heading_ {
        margin: 20px 0 0;
        font-size: 12px;
        font-family: montserrat_semibold;
        font-weight: 600;
      }

      .android_build_fields {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-top: 5px;
        .ant-form-item{
          margin-bottom: 0;
        }
        .input_field {
          @include width(135px);
          padding-right: 30px;

          input {
            padding: 8px;
            width: 100%;
            background: #F8F8F8;
            border: 2px solid #DEDEDE;
            box-sizing: border-box;
            border-radius: 10px;
            font-family: montserrat_medium;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.3125px;
            color: #3E4A58;

            &::placeholder {
              font-family: montserrat_medium;
              font-weight: 500;
            }
          }
        }

        .main_switch_block {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          padding-right: 15px;

          h5 {
            padding-right: 15px;
            font-family: montserrat_semibold;
            font-weight: 600;
            margin: 0;
          }

          .switch_box {
            min-width: 140px;
            @include width(140px);
          }
        }
      }
    }

    .ios_build {
      margin-top: 30px;
    }
  }

  h4 {
    margin: 0;
    text-align: left;
    font-family: montserrat_semibold;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    text-transform: capitalize;
    color: #25282C;
  }

  button.save_btn {
    max-width: 84px;
    width: 100%;
    background: #C00C2D;
    border-radius: 20px;
    padding: 10px;
    border: none;
    font-family: montserrat_bold;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;
    border: 1px solid #C00C2D;
    letter-spacing: 0.3px;
    cursor: pointer;
    &:hover {
      background-color: transparent;
      color: #C00C2D;
    }
  }

  .switch_box {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    width: calc(100% - 84px);

    .switch input {
      opacity: 0;
      position: absolute;
      z-index: 9;
      width: 100%;
      height: 20px;
      top: 0;
      left: 0;
      right: 0;
      display: block;
      cursor: pointer;
    }

    .switch {
      display: inline-block;
      max-width: 42px;
      width: 100%;
      position: relative;
    }

    .slider {
      max-width: 42px;
      width: 100%;
      height: 20px;
      @include position(absolute);
      border-radius: 20px;
      background: #457B9D;
      cursor: pointer;
      border: 4px solid transparent;
      overflow: visible;
      transition: 0.4s;
      z-index: 1;
    }

    .slider:before {
      position: absolute;
      content: "";
      width: 26px;
      height: 26px;
      background: #FFFFFF;
      border-radius: 100px;
      top: -8px;
      left: -6px;
      -webkit-transform: translateX(-0px);
      transform: translateX(-0px);
      -webkit-transition: .4s;
      transition: .4s;
      -webkit-box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.23);
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
    }

    .switch input:checked+.slider:before {
      -webkit-transform: translateX(20px);
      transform: translateX(20px);
    }

    .off,
    .on {
      color: #959595;
      margin: 0;
      font-family: montserrat_semibold;
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;
      text-align: right;
      letter-spacing: 0.6px;
      text-transform: capitalize;
    }

    .off {
      padding-right: 12px;
    }

    .on {
      padding-left: 12px;
    }

    .off.checked,
    .on.checked {
      color: $black;
    }

    input[type="checkbox"]:disabled~span.slider {
      background: rgb(69 124 158 / 50%);
    }
  }

  @media (max-width: 991px) {

    .input_field,
    .main_switch_block,
    .save_btn {
      margin: 5px 0;
    }

    .android_build_fields {
      margin-top: 2px;
    }
  }

  @media (max-width: 768px) {

    .main_switch_block h5 {
      margin: 0 0 7px !important;
    }
  }
}