footer {
  background-color: #1E1E1E;
  padding: 93px 20px 30px;

  .copyright {
    font-family: montserrat_medium;
    font-weight: 500;
    text-align: center;
    margin: 0;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.05em;
    color: #BCBCBC;
  }

  @media (max-width: 600px) {
    padding: 30px 20px;
  }
}