.ReactModal__Overlay--after-open {
  background-color: transparent !important;
}

.ant-modal {
  padding-bottom: 0px !important;

  .ant-modal-content {
    border-radius: 10px;
  }
}

.modal_dialog {
  border-radius: 1rem !important;
}

.ant-modal-close-x {
  color: black;
}

.modal {
  max-height: 90vh;
  padding-top: 3.7rem;
  padding-bottom: 3.7rem;
  margin: 0 auto;
  margin-top: 5vh;
  position: relative;
  background: #fff;
  border-radius: 1.5rem;
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.13);

  &>button {
    position: absolute;
    right: 1rem;
    top: 1rem;
    border: 0;
    background-image: url(./../images/icon_cross.svg);
    background-size: 1.2rem 1.2rem;
    background-repeat: no-repeat;
    height: 1.2rem;
    width: 1.2rem;
    font-size: 0;
  }

  &.userProfile_Popup,
  &.UserProfilePopup,
  &.UserProfilePostPopup {
    @include width(635px);
    width: 100% !important;

    .form_group {
      h4 {
        font-family: montserrat_semibold;
      }
    }
  }

  &.addPhy {
    max-width: 956px;
    width: 100% !important;



    .custom_modal {
      padding: 0 10px 50px 10px;
      overflow-y: auto;
      max-height: 80vh;
      @include scrollbar;

      @media (max-width: 768px) {
        padding: 0;
      }

      .form {
        display: flex;
        max-width: 674px;
        width: 100%;
        margin: 0 auto;

        .form_heading {
          max-width: 445px;
          width: 100%;
        }

        .form_group.profile {
          max-width: 120px;
          width: 100%;
          padding: 0 20px 0 0;
        }

        .physician_form {
          width: calc(100% - 120px);
          padding-left: 20px;

          .form_action {
            margin-top: 46px;
          }

          .form_group {
            margin-bottom: 8px;

            label {
              font-size: 14px;
              line-height: 20px;
              letter-spacing: 0.3125px;
              color: #595959;
            }
          }
        }

        .form_row,
        .form_group {
          max-width: 434px;
          width: 100%;
        }

        @media (max-width: 768px) {
          & {

            .form_group.profile,
            .authorized_profile {
              margin: 0 auto !important;
              padding: 0 10px 20px !important;
            }
          }
        }

        @media (max-width: 580px) {
          & {
            flex-direction: column;

            .physician_form .form_row,
            .physician_form .form_group {
              max-width: 100% !important;
            }

            .physician_form,
            .authorized_form_model {
              width: 100% !important;
              padding-left: 0 !important;
            }
          }
        }
      }

      .authorized_form {
        max-width: 700px;

        .user_profile_pic {
          text-align: center;
          margin-bottom: 10px;

          .addnew {
            right: 6px;
          }
        }

        .authorized_profile {
          max-width: 140px;
          padding-right: 25px;
          padding: 0 25px 0 0;
        }

        .authorized_form_model {
          width: calc(100% - 140px);
          padding-left: 0px;
          margin-left: auto;
        }

        .user_profile {
          label {
            text-align: center;
          }
        }

        @media (max-width: 768px) {
          & {
            .authorized_form_model {
              h3 {
                max-width: 100%;
              }
            }
          }
        }
      }
    }
  }

  &.errPop {
    max-width: 40.9rem;

    &>button {
      display: none;
    }

    h3 {
      font-size: 14px;
      line-height: 17px;
      text-transform: capitalize;
    }

    p {
      @extend .fs_12;
    }

    .otpMsg {
      .form_row {
        .form_group {
          width: 41px;

          &:not(:last-child) {
            margin-right: 0.9rem;
          }

          input {
            font-size: 40px;
            line-height: 42px;
            font-weight: 600;
            padding-left: 0;
            padding-right: 0;
            text-align: center;
          }
        }
      }
    }

    .form_action {
      margin-bottom: 0;
    }
  }

  .form_heading {
    margin-bottom: 24px;
    font-size: 16px;
    line-height: 20px;
    text-transform: capitalize;
    padding: 0 20px 0 80px;

    @media (max-width: 768px) {
      & {
        padding: 0;
      }
    }
  }
}

.modal.errorPop,
.CommonPop {
  max-width: 400px !important;
}

.modal.errorPop,
.CommonPop {
  .ant-modal-content {
    border-radius: 1rem;
  }
}

.modal.primaryPop {
  max-width: 553px !important;
  width: 100% !important;
  text-align: center;

  &.changemin-modal {
    max-width: 523px !important;
    width: 100% !important;
  }
}

.ant-modal-close {
  top: 12px !important;
  right: 12px !important;
}

.ant-modal-close-x {
  width: 20px !important;
  height: 20px !important;
  line-height: 20px !important;
}

.ant-modal-body {
  @media (max-width: 768px) {
    & {
      padding: 20px 9px 20px 20px !important;
    }
  }
}

.ant-modal {
  top: 0 !important;
}

.ant-modal-wrap {
  margin: 0 !important;
  padding: 20px !important;
  height: 100vh !important;
  width: 100vw !important;
  display: flex;
  align-items: center !important;
  justify-content: center !important;
}

// delete popup
.ant-modal.modal.deletePop, .ant-modal.modal.GhostLoginAlert {
  max-width: 410px !important;
  width: 100% !important;

  .ant-modal-body {
    padding: 19px 20px 28px !important;
  }
}

.main_delete_modal {
  max-width: 324px;
  width: 100%;
  margin: 0 auto;

  @media (max-width: 680px) {
    .form_action {
      margin: 0 auto !important;
    }
  }


  &.disable_physician_modal,
  &.enable_physician_modal {
    max-width: 350px;

    .delete_content {
      padding-bottom: 18px;
    }

    .reversed {
      color: #495568;
    }
  }

  &.disable_physician_modal {
    span.proceed {
      padding: 0 0 26px;
    }

    .delete_content {
      padding-bottom: 34px;

      p {
        max-width: 270px;
      }
    }
  }

  &.enable_physician_modal {
    .proceed {
      padding-top: 16px;
    }

    .reversed {
      @include width(340px);
      margin: 0 auto;
    }
  }

  h4 {
    @extend .montserrat_regular;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    text-transform: capitalize;
    color: #000000;
    padding-bottom: 16px;
    margin: 0;
  }

  .delete_content {
    padding-bottom: 26px;

    p {
      @extend .montserrat_regular;
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
      text-align: center;
      color: #495568;
      max-width: 250px;
      width: 100%;
      margin: 0 auto;
      font-family: 'montserrat_medium';
      font-weight: 500;

      &:not(:last-child) {
        padding-bottom: 10px;
      }
    }
  }
  .ghost_login_cnt{
    padding-bottom: 26px;

    p {
      @extend .montserrat_regular;
      font-weight: 600;
      font-size: 12px;
      line-height: 15px;
      text-align: center;
      color: #495568;
      max-width: 308px;
      width: 100%;
      margin: 0 auto;
      font-family: 'montserrat_medium';
      font-weight: 500;

      &:not(:last-child) {
        padding-bottom: 10px;
      }
    }
  }

  .reversed,
  .proceed {
    @extend .montserrat_regular;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #000000;
    display: block;
  }

  .reversed {
    font-size: 12px;
  }

  .proceed {
    padding: 18px 0 26px;
  }

  .delete_modal_btn {
    margin: 0;

    .btn {
      font-family: "montserrat_medium";
      margin: 5px 6px;
      font-weight: 500;
    }
  }
}

.modal {
  .modal_btn {
    margin: 0;
    flex-wrap: wrap;

    button {
      min-width: unset;
      width: 46%;
      margin: 5px 6px;
    }
  }
}

// transfer patients popup
.transferPatient {
  max-width: 956px !important;
  width: 100% !important;

  .ant-modal-body {
    padding: 36px 20px !important;

    .main_transfer_patients {
      h4 {
        @extend .montserrat_regular;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        text-transform: capitalize;
        color: #25282c;
        margin-bottom: 32px;
      }

      .transfer_form_grup {
        max-width: 438px;
        width: 100%;
        margin: 0 auto 28px;
      }

      .main_select_patient {
        max-width: 524px;
        width: 100%;
        margin: 0 auto;

        h6 {
          @extend .montserrat_regular;
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.3125px;
          color: #595959;
          padding: 0 0 10px 16px;
          margin: 0;
        }

        .select_patient_box {
          padding: 14px 0;
          background: #f4f4f4;
          border-radius: 3px;
          height: 328px;
          overflow: hidden;

          .select_all_patient_box {
            .main_select_all {
              padding: 0 18px;
            }

            .checkbox {
              span {
                width: 12px;
                height: 12px;
                background: #3e4a58;

                &::after {
                  content: "";
                  position: absolute;
                  display: none;
                }
              }

              label {
                @extend .montserrat_regular;
                font-weight: 600;
                font-size: 10px;
                line-height: 12px;
                color: #000000;
                margin-left: 8px;
              }

              &>[type="checkbox"]:checked+span:after {
                display: block;
                left: 3px;
                top: 0;
                width: 5px;
                height: 8px;
                border: solid white;
                border-width: 0 2px 2px 0;
                -webkit-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                transform: rotate(45deg);
                background-color: unset;
              }

              &>[type="checkbox"]:checked+span::before {
                display: none;
              }
            }

            .main_patients_details {
              width: 100%;
              height: 230px;
              overflow-y: auto;

              .patients_details {
                width: 100%;

                &.patients_title {
                  @extend .comman_flex;
                }

                h4 {
                  @extend .montserrat_regular;
                  font-size: 12px;
                  line-height: 20px;
                  letter-spacing: 0.3125px;
                  text-transform: capitalize;
                  color: #000000;
                  font-weight: 600;
                  text-align: left;
                  margin: 0;
                  width: 50%;

                  &:first-child {
                    padding-left: 22px;
                  }
                }

                .main_detail_block {
                  @extend .comman_flex;
                }

                .patients_name_sub {
                  display: flex;
                  align-items: flex-start;
                  width: 50%;
                  margin-top: 7px;
                  padding-right: 10px;

                  .form_group {
                    margin: 6px 0 0;
                  }

                  span {
                    padding-left: 7px;
                  }
                }

                .patients_email_sub {
                  width: 50%;

                  span {
                    margin-top: 7px;
                  }
                }

                .main_detail_block {
                  h4 {
                    padding-left: 18px;
                  }
                }

                .patients_email_sub,
                .show_name {
                  .pl_1 {
                    @extend .montserrat_regular;
                    font-weight: 600;
                    font-size: 13px;
                    line-height: 20px;
                    letter-spacing: 0.3125px;
                    color: #636363;
                    display: inline-block;
                  }

                  span {
                    @extend .montserrat_regular;
                    font-weight: 600;
                    font-size: 13px;
                    line-height: 20px;
                    letter-spacing: 0.3125px;
                    color: #636363;
                    display: inline-block;
                  }
                }
              }
            }
          }
        }
      }

      .modal_btn {
        max-width: 350px;
        width: 100%;
        margin: 50px auto 0;

        @media (max-width: 480px) {
          max-width: 100%;
        }
      }
    }
  }

  .sub_transfer_patients {
    max-height: 70vh;
    overflow-y: auto;
  }
}

.form_group.search {
  max-width: 310px;
  width: 100%;
  margin: 0 auto 16px;
  padding: 0 10px;

  input {
    padding: 10px 33px;
    border-radius: 30px;
    color: #3e4a58;
    letter-spacing: 0.8px;
    font-family: montserrat_medium;
    font-weight: 500;
    border-width: 2px;

    &::placeholder {
      letter-spacing: 0.8px;
      font-family: montserrat_medium;
    }
  }

  .serch_icon {
    position: absolute;
    left: 16px;
    top: 46%;
    transform: translate(0, -50%);
  }
}

// minutePopup
.addMinutePop {
  @include width(500px);

  h3 {
    padding: 0 !important;
  }

  .ant-modal-body {
    padding: 20px !important;
  }

  .custom_modal {
    padding: 0;

    .form {
      max-width: 365px;
      width: 100%;
      margin: 0 auto;
      display: block;
    }

    .form_group {
      margin-top: 30px;
      text-align: center;

      label {
        text-align: left;
      }
    }
  }
}

// AddAppoinment popup
.modal.addAppointmentPop {
  max-width: 956px !important;
  width: 100% !important;
  max-height: 100vh !important;
  background-color: unset;
  box-shadow: unset;

  .ant-modal-content {
    margin: 20px 0;
  }

  h4 {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    text-transform: capitalize;
    color: #25282c;
    @extend .montserrat_regular;
    margin-bottom: 35px;
  }

  .ant-modal-body {
    padding: 34px 9px 24px 24px !important;

    .sub_appoinment_block {
      max-height: 80vh;
      overflow-y: auto;
    }

    .addappoinment_block {
      max-width: 440px;
      width: 100%;
      margin: 0 auto;
    }

    .appoinment_time {
      max-width: 300px;
      width: 100%;
      margin-bottom: 27px;

      .form_group {
        margin: 0;
        width: 50%;

        &:first-child {
          padding-right: 10px;
        }

        &:nth-child(2) {
          padding-left: 10px;
        }

        span {
          display: inline-block;
        }

        .time_block {
          display: flex;
          width: 100%;
          align-items: center;

          p {
            @extend .montserrat_regular;
            margin: 0;
            padding-left: 6px;
            font-weight: 600;
            font-size: 12px;
            line-height: 15px;
            color: #3e4a58;
          }
        }
      }
    }

    .main_call_length {
      margin-bottom: 28px;

      p {
        @extend .montserrat_regular;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #495568;
        padding-bottom: 12px;
        margin: 0;
      }

      .radio_buttons_block {
        display: flex;
        align-items: center;

        .min_block {
          @extend .montserrat_regular;
          display: block;
          cursor: pointer;
          position: relative;

          &:first-child {
            padding-right: 8px;
            padding-left: 25px;
          }

          &:nth-child(2) {
            padding-left: 25px;
          }

          label {
            font-weight: 600;
            font-size: 12px;
            line-height: 15px;
            text-transform: lowercase;
            color: #3e4a58;
          }

          input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            top: 50%;
            left: 0;
            transform: translate(0, -50%);
            height: 16px;
            width: 16px;
            z-index: 99;
          }

          .check {
            position: absolute;
            top: 50%;
            left: 0;
            transform: translate(0, -50%);
            height: 16px;
            width: 16px;
            background-color: #457b9d;
            border-radius: 100%;

            &::after {
              content: "";
              position: absolute;
              display: none;
            }
          }

          input:checked~.check {
            background-color: #457b9d;
          }

          input:checked~.check::after {
            display: block;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background: #fff;
          }
        }
      }
    }

    .main_note_block {
      margin-bottom: 32px;

      textarea {
        background: rgba(229, 229, 229, 0.5);
        border-radius: 10px;
        border: none;
        resize: none;
        height: 80px;
        @extend .montserrat_regular;
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        color: #7c7c7c;
        padding: 8px;

        &::placeholder {
          font-size: 12px;
          line-height: 15px;
          color: #7c7c7c;
        }
      }

      span {
        @extend .montserrat_regular;
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        color: #000000;
        display: block;
      }
    }

    .select_patient_block {
      h6 {
        @extend .montserrat_regular;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #495568;
        margin-bottom: 14px;
      }

      .current_patient_box {
        height: 400px;
        overflow: hidden;

        .current_patient_header {
          @include flex;
          flex-wrap: wrap;
          padding: 5px 27px 5px 7px;
          background-color: #457b9d;
          border-top-left-radius: 5px;
          border-top-right-radius: 5px;

          @media (max-width: 991px) {
            padding-right: 10px;
          }

          .form_group.search {
            max-width: 190px;
            margin: 0;
            padding: 5px;

            input {
              padding: 5px 20px 5px 33px !important;
              border-radius: 30px !important;
              min-height: unset !important;
              background: #ffffff;
              border: 1.5px solid #dedede;
              font-size: 14px;
              line-height: 20px;
              letter-spacing: 0.3125px;
              color: #3e4a58;
              font-weight: 600;
              @extend .montserrat_regular;
            }
          }

          h6 {
            @extend .montserrat_regular;
            margin-bottom: 0;
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
            text-align: right;
            letter-spacing: 0.9px;
            color: #ffffff;
            padding: 5px;
          }
        }

        .main_cp_list {
          height: 342px;
          overflow-y: auto;
          background: rgba(229, 229, 229, 0.5);

          .main_cp_list_col {
            padding: 7px 20px 9px 10px;
            background-color: #dadada;
            filter: drop-shadow(4px 2px 12px rgba(0, 0, 0, 0.1));
            @include flex;
            flex-wrap: wrap;

            @media (max-width: 991px) {
              padding-right: 10px;
            }

            &:nth-child(2n) {
              background-color: #f8f8f8;
            }

            .cp_profile_block {
              display: flex;
              align-items: center;
              padding: 5px 10px;

              .profile {
                max-width: 24px;
                width: 100%;
                height: 24px;

                img {
                  @include image;
                  height: 100%;
                  object-fit: cover;
                  border: 1px solid #ffffff;
                  box-sizing: border-box;
                  filter: drop-shadow(0px 4px 25px rgba(0, 0, 0, 0.3));
                  border-radius: 100%;
                }
              }

              span {
                @extend .montserrat_regular;
                display: block;
                font-weight: 600;
                font-size: 12px;
                line-height: 20px;
                letter-spacing: 0.9px;
                color: #2d2d2d;
                padding-left: 8px;
              }
            }

            .cp_checkbox {
              padding: 5px 10px;

              .checkbox {
                margin: 0;

                [type="checkbox"] {
                  height: 20px;
                  width: 20px;
                  border-radius: 100%;
                  cursor: pointer;
                }

                span {
                  width: 20px;
                  height: 20px;
                  border: 2px solid #3e4a58;
                  border-radius: 100%;

                  &::after {
                    content: "";
                    position: absolute;
                    display: block;
                    top: 45%;
                    width: 6px;
                    height: 10px;
                    left: 50%;
                    border: solid #3e4a58;
                    border-width: 0 2px 2px 0 !important;
                    transform: translate(-50%, -50%) rotate(45deg);
                    background-color: unset;
                  }
                }

                &>[type="checkbox"]:checked+span {
                  background: #3e4a58;
                }

                &>[type="checkbox"]:checked+span:after {
                  border: solid white;
                  background-color: unset;
                }

                &>[type="checkbox"]:checked+span::before {
                  display: none;
                }
              }
            }
          }

          &::-webkit-scrollbar {
            width: 8px;
          }

          &::-webkit-scrollbar-track {
            background: #bfc6cf;
          }

          &::-webkit-scrollbar-thumb {
            background: #3e4a58;
          }

          &::-webkit-scrollbar-thumb:hover {
            background: #c4c4c4;
          }
        }
      }
    }

    .appoinment_btn {
      max-width: 324px;
      width: 100%;
      margin: 50px auto 30px;

      @media (max-width: 991px) {
        margin: 20px auto 0;
        max-width: 100%;
      }
    }
  }
}

// authorizedPop
.ant-modal.modal.authorizedPop {
  max-width: 956px !important;
  width: 100% !important;

  .custom_modal {
    padding-bottom: 29px;
  }

  &.add_specialist {
    .authorized_form_model {
      max-width: 460px;
      width: 100%;
      margin: 0 auto;
    }

    .ant-modal-body {
      padding: 38px 20px 42px;
    }

    .custom_modal {
      padding-bottom: 0;
    }

    .form_group.form_action {
      margin-top: 82px;
    }

    .form_heading {
      padding-left: 0;
    }
  }
}

.edit_form_group {
  max-width: 520px !important;

  .text_add {
    align-items: center;

    input {
      max-width: 434px;
      width: 100%;
    }

    span {
      position: unset;
      transform: unset;
      width: calc(100% - 434px);
      padding-left: 10px;
    }

    @media (max-width: 750px) {
      & {
        flex-wrap: wrap;

        span {
          width: 100%;
          padding-top: 4px;
        }
      }
    }

    @media (max-width: 580px) {
      & {
        input {
          max-width: 100%;
        }
      }
    }
  }
}

.user_profile {
  &_pic {
    position: relative;

    &>img {
      height: 100px;
      width: 100px;
      border-radius: 100%;
      object-fit: cover;
    }

    .addnew {
      height: 2.6rem;
      width: 2.6rem;
      position: absolute;
      bottom: 4px;
      right: 2px;

      input[type="file"] {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        opacity: 0;
        cursor: pointer;
        font-size: 0;
        padding-left: 26px;
      }
    }
  }
}

.profile_label {
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  font-family: "Raleway_Medium", sans-serif;
  text-align: center;
  text-transform: capitalize;
  color: #000000;
  margin-top: 12px;
}

.comman_flex {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.change-min_modal {
  &.main_delete_modal {
    max-width: 100%;
  }

  h5 {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    text-transform: capitalize;
    color: #000000;
    margin-bottom: 18px;
  }

  .form_group {
    margin-bottom: 14px;

    label {
      font-size: 12px;
      line-height: 20px;
      text-align: center;
      letter-spacing: 0.3125px;
      color: #595959;
      margin-bottom: 10px;
    }

    select {
      max-width: 186px;
      width: 100%;
      color: #000;
    }

    &.form_action {
      margin-bottom: 0;

      .btn {
        margin: 5px 8px;
      }
    }
  }

  .delete_content {
    @include width(370px);
    margin: 0 auto 18px;
    padding: 0;
  }

  .proceed {
    padding: 0 0 22px;

    @media (max-width: 768px) {
      padding: 0;
    }
  }
}

.lodar_modal {
  min-height: 789px;
}

.lodar_modal_phy {
  min-height: 700px;
}

.delete_content.delete-modal_content {
  padding-bottom: 0;
}

.show_name {

  .pl_label_1,
  .pl_label_2 {
    @extend .montserrat_regular;
    font-weight: 600;
    font-size: 13px;
    line-height: 20px;
    letter-spacing: 0.3125px;
    color: #636363;
    display: inline-block;
  }
}

.date-added-leads-website {
  img {
    margin-left: 5px;
    cursor: pointer;
  }
}

// web leads popup

.web_leads_modal {
  padding: 10px 12px 20px;
  transform: scale(0);
  transition: 0.5s;
  position: absolute;
  z-index: 99;
  top: 60px;
  right: 50px;
  max-width: 382px;
  min-width: 382px;
  width: 100%;
  box-sizing: border-box;
  background: #ffffff;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  max-height: 215px;
  overflow-y: auto;
}

.web_leads_container {
  box-sizing: border-box;
}

.web_cmt_td {
  position: relative;
}

.web_leads_modal.open {
  transform: scale(1);
}

.web_cmt_td .cross {
  background: none;
  border: none;
  display: block;
  margin-left: auto;
}

.cmt_modal_txt {
  font-family: "montserrat_semiBold";
  font-weight: 600;
  font-size: 12px;
  line-height: 17px;
  color: #495568;
  display: block;
  padding-top: 10px;
  margin: 0;
}

.main_web_leads .clinic_data_list tr:last-child,
.main_web_leads .clinic_data_list tr:nth-last-child(2),
.main_web_leads .clinic_data_list tr:nth-last-child(3),
.main_web_leads .clinic_data_list tr:nth-last-child(4) {
  .web_leads_modal {
    top: auto;
    margin-bottom: 0;
    bottom: 70%;
  }
}

.main_web_leads .clinic_data_list tr:nth-child(-n + 3) .web_leads_modal {
  top: 80%;
  margin-bottom: 0;
  bottom: auto;
  z-index: 999;
}

.main_web_leads .table_responsive {
  min-height: 60rem;
}

.setting-popup {
  .delete-pop {
    .title {
      margin: 15px 0 0;
      font-size: 22px;
    }

    .btn_primary {
      margin-bottom: 21px;
      margin-top: 0;
    }
  }
}

// .main_flagged_post_cmnt{
//   @include width(330px);
//   margin: 0 auto;
// }

.main_flagged_post_cmnt,
.flagged_items_block {
  &.loading_ {
    min-height: 20vh;
  }
}

.flaggedItems_Popup,
.FlaggedPhysicianItemsPopup,
.addPhysicianAdvanceFlags, .GroupPostList, .GroupFlaggedItemsPopup {
  max-width: 600px !important;
  width: 100% !important;

  .content-loader {
    transform: unset;
    height: auto;
  }

  .ant-modal-body {
    padding: 37px 0 20px;

    .flagged_items_block {

      h2 {
        font-family: montserrat_semibold;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        text-transform: capitalize;
        color: #25282c;
        margin-bottom: 0;
        padding: 0 20px;
      }

      .flagged_items_list_block {
        padding-top: 60px;
        overflow-y: auto;
        height: auto;
        max-height: 660px;

        @media (max-width: 1280px) {
          max-height: 60vh;
        }

        .main_flag_div {
          border-bottom: 1px solid #dddddd;
          padding: 40px 55px 16px 20px;

          &:last-child {
            border: none;
          }

          &:first-child {
            padding: 0 55px 16px 20px;
          }

          @media (max-width: 767px) {
            padding: 40px 38px 16px 30px;

            &:first-child {
              padding: 0 38px 16px 30px;
            }
          }

          @media (max-width: 600px) {
            padding: 40px 10px 16px 10px;

            &:first-child {
              padding: 0 10px 16px 10px;
            }
          }
        }

        .flagged_chemo_therapy_row {
          display: block;

          @include width(486px);
          margin-left: auto;
        }

        .inner_flagged_row {
          .detail {
            width: calc(100% - 36px) !important;
          }
        }
      }

    }
  }
}

.inner_flagged_row {
  margin-top: 25px;
}

.postPopup_wrapper {
  .outer_flagged_row {
    display: block;

    .user_profile {
      max-width: 100%;
      height: auto;
      display: flex;
      align-items: flex-start;
    }

  }
}

.outer_flagged_row,
.inner_flagged_row {
  display: flex;
  align-items: flex-start;
  //justify-content: space-between;

  .user_profile {
    @include width(45px);
    height: 45px;

    img {
      border-radius: 100%;
      object-fit: cover;
      display: block;
      width: 45px;
      height: 45px;
      border: 2px solid #FFFFFF;
      // filter: drop-shadow(0px 0px 15px rgba(0, 0, 0, 0.15));
    }
  }

  .main_detail_block {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: calc(100% - 45px);
  }


  .detail {
    display: block;
    padding: 0 0 0 10px;

    .details_flag_tag {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      margin: 11px 0 20px;
    }

    @extend .comman_postpopup_style;

    @media (max-width: 991px) {
      padding: 0 0 0 10px;
    }

  }

  .user_detail_wrapper {
    padding: 4px 0 0 10px;
    width: calc(100% - 45px);
    @extend .comman_postpopup_style;
  }

  .details_flag_tag {
    &.flag_show {
      p.text {
        max-width: 272px;
        width: 100%;
      }
    }
  }
}

.comman_postpopup_style {
  h4 {
    font-family: ProximaNova_Bold;
    font-size: 14px;
    line-height: 14px;
    color: #495568;
    text-transform: none;
    margin-bottom: 2px;
    text-align: left;
    letter-spacing: 0;
    width: 100%;
  }

  .tag_name {
    display: block;
    font-family: ProximaNova_Regular;
    font-size: 12px;
    line-height: 12px;
    text-transform: capitalize;
    color: #495568;
    margin-bottom: 3px;
      &.group_member_list{
        font-size: 14px;
        font-weight: bold;
      }

    span.tag {
      display: inline-block;
      font-family: ProximaNova_Regular;
      color: #c00c2d;
      text-transform: none;
      padding-left: 2px;
    }
  }

  .time {
    display: block;
    font-family: ProximaNova_SemiBold;
    font-weight: 600;
    font-size: 11px;
    line-height: 11px;
    color: #3e4a58;
  }

  .time_wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .post_popup_wrapper {
      display: flex;
      align-items: center;
      max-width: 24px;
      width: 100%;
      height: 10px;
      background-color: #E5E5E5;
      border-radius: 10px;
      cursor: pointer;
      justify-content: center;

      span {
        background-color: rgba(73, 85, 104, 0.5);
        width: 4px;
        height: 4px;
        border-radius: 100%;
        display: block;

        &:not(:last-child) {
          margin-right: 2px;
        }
      }
    }
  }
}
.ActivationHashTag{
  .flagged_items_block {
    h3{
      font-size: 14px;
      line-height: 17px;
    }
  }
  .main_flag_div_hash{
    .tag_name,.hash_count{
      font-family: "sf_pro_display";
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      letter-spacing: 0.02em;
      text-transform: capitalize;
      color: #000000;
    }
  }
}

.review_btn {
  @extend .flagged_btn;
  margin-top: 16px;
  margin-left: 42px;
}

.flag_count_block {
  display: flex;
  align-items: center;
  padding-left: 8px;

  img {
    padding-right: 7px;
  }
}

.inner_flagged_row {
  // padding-left: 23px;
  padding-left: 50px;
  position: relative;

  &:last-child::after {
    display: none;
  }

  &::after {
    content: '';
    position: absolute;
    height: 100%;
    width: 1px;
    background-color: #CED5DA;
    left: 17px;
    bottom: -20px;
    z-index: 1;
  }

  .user_profile {
    @include width(36px);
    height: auto;
    z-index: 9;

    img {
      width: 36px;
      height: 36px;
    }
  }

  .detail {
    padding-left: 15px;
    width: calc(100% - 36px);
  }

  .name {
    font-family: 'ProximaNova_Medium';
    font-size: 12px;
    line-height: 12px;
    text-transform: capitalize;
    color: #495568;
  }

  .note_block {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin: 11px 0 0;

    p {
      @extend .flagged_font_style;
      //color: #C00C2D;

      padding-right: 10px;
    }

    .red_note {
      color: #c00c2d !important;
    }

    .text {
      span {
        color: #c00c2d !important;
        font-weight: 600;
        cursor: pointer;
      }
    }
  }
}

.flagged_btn {
  background: #c00c2d;
  border: 1.5px solid #c00c2d;
  box-sizing: border-box;
  border-radius: 30px;
  font-family: ProximaNova_Bold;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.3125px;
  color: #ffffff;
  @include width(83px);
  padding: 5px 5px 4px;
}

.flagged_font_style {
  font-family: ProximaNova_Regular;
  font-size: 13px;
  line-height: 17px;
  margin: 0;
  @include width(272px);
  letter-spacing: 0.1px;
}

.modal.flaggedPostComments_popup,
.modal.FlaggedPhyPostComments,
.modal.ViewPostPopup,.modal.FlaggedPostComment,
.modal.GroupFlaggedPostComments {
  max-width: 600px !important;
  width: 100% !important;

  .main_flagged_postcmt {
    height: auto;
    max-height: 640px;
    overflow-y: auto;
    padding-right: 26px;

    @media (max-width: 767px) {
      padding-right: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background: rgba(73, 85, 104, 0.8);
      border-radius: 20px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar {
      width: 5px;
    }
  }

  .ant-modal-body {
    padding: 37px 10px 25px 36px;

    @media (max-width: 767px) {
      padding: 40px 9px 20px 20px !important;
    }

    .flagged_head {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      margin-bottom: 34px;
      padding-right: 26px;

      @media (max-width: 767px) {
        padding-right: 10px;
      }

      button.prev_btn {
        background-color: unset;
        border: none;
        padding: 0;
        line-height: 0;
      }

      h2 {
        font-family: montserrat_semibold;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        text-transform: capitalize;
        color: #25282c;
        margin: 0;
        padding: 0 10px;

        @media (max-width: 767px) {
          padding: 0 10px 0;
        }
      }

      .next_btn {
        @extend .flagged_btn;
      }
    }

    .inner_flagged_postcmt {
      @include width(385px);
      margin: 0 auto;

      @media (max-width: 767px) {
        @include width(490px);
      }
    }

    .inner_flagged_row {
      margin: 0 0 14px 6px;
      padding-left: 50px;
    }

    .details_flag_tag {
      p.red_note.text {
        color: #C00C2D !important;

        .read-or-hide {
          color: #C00C2D !important;
        }
      }
    }

    .outer_flagged_row {
      margin-bottom: 16px;

      &:last-child {
        margin-bottom: 0;
      }

      .detail {
        width: calc(100% - 55px);
        padding: 0;

        .title_img_wrapper {
          margin: 12px 0 0;
          width: 100%;

          img {
            height: 100px;
            width: 100%;
            object-fit: cover;
            border-radius: 5px;
          }
        }

        p {
          @extend .flagged_font_style;
          color: #3e4a58;
          margin: 0;
          max-width: 100%;

          span {
            display: inline-block;
            color: #3e4a58;
            cursor: pointer;
            padding: 0 0 0 3px;
          }
        }
      }
    }

    .note_gray {
      color: #3e4a58;
    }

    .like_cmt_count_block {
      display: flex;
      margin: 11px 0 8px;

      .lc_count_block {
        display: flex;
        align-items: center;

        &:not(:last-child) {
          padding-right: 18px;
        }

        img {
          padding-right: 6px;
        }

        span {
          font-family: 'ProximaNova_SemiBold';
          font-size: 11px;
          line-height: 11px;
          text-align: center;
          text-transform: capitalize;
          color: #457B9D;
          border-radius: 20px;
          padding: 0;
          display: block;
        }
      }
    }

    .hide_keep_block {
      display: flex;
      margin-top: 16px;

      .hide_keep_btn {
        @extend .flagged_btn;
        background: #ffffff;
        box-sizing: border-box;
        border-radius: 30px;

        &.hide_btn {
          border: 1.5px solid #c00c2d;
          color: #c00c2d;
          margin-right: 12px;
        }

        &.keep_btn {
          border: 1.5px solid #4ebc1a;
          color: #4ebc1a;
        }
      }
    }

    .flagged_num {
      position: absolute;
      font-family: ProximaNova_Regular;
      font-size: 12px;
      line-height: 16px;
      color: #ffffff;
      max-width: 24px;
      width: 100%;
      height: 24px;
      display: grid;
      place-items: center;
      padding: 1px 0 0;
      background: #c00c2d;
      border-radius: 100%;
      top: -10px;
      right: -10px;
      z-index: 9;
    }

    .flagged_slider {
      margin-top: 18px;
      width: 100%;
      position: relative;

      &.postBy_flag_slider {
        button.slick-arrow {
          display: none !important;
        }

        ul.slick-dots {
          width: auto !important;
          left: 50%;
          transform: translate(-50%, 0);
          bottom: 20px;
          background: rgba(45, 45, 45, 0.5);
          border-radius: 20px;
          padding: 3px;
          line-height: 1;
          display: flex !important;
          align-items: center;

          li {
            width: 6px;
            height: 6px;
            display: flex;
            margin: 0 3px !important;

            button {
              width: 100%;
              height: 100%;
              padding: 0;
              font-size: 0 !important;
              line-height: 0;

              &::before {
                content: '' !important;
                font-size: 0;
                top: 1px;
                left: 0;
                width: 4px;
                height: 4px;
                opacity: 1;
                border-radius: 100%;
                background-color: rgba(217, 217, 217, 0.5);
              }
            }

            &.slick-active {
              button {
                &::before {
                  width: 6px;
                  height: 6px;
                  opacity: 1;
                  background-color: #D9D9D9;
                  font-size: 0;
                  line-height: 1;
                  top: 0;
                }
              }
            }
          }
        }
      }

      .image_block {
        img {
          border-radius: 10px;
          width: 100%;
          height: 272px;
          display: block;
          object-fit: cover;
        }
      }

      .slick-next,
      .slick-prev {
        width: auto;
        height: auto;
      }

      // slider buttons css
      .slick-prev {
        left: -18px;

        &:before {
          content: "";
          @extend .slider_button_style;
          left: 0;
        }
      }

      .slick-next {
        right: -15px;

        &:before {
          @extend .slider_button_style;
          transform: rotate(180deg);
        }
      }

      .slider_button_style {
        content: "";
        background: url(../images/prev_icon.svg) no-repeat;
        background-size: contain;
        width: 8px;
        height: 14px;
        display: block;
      }
      .main_covor_img{
        .video-react {
          border-radius: 5px;
          .video-react-big-play-button{
            position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
          }
        }
      }
    }
  }
}

.FlaggedPhyPostComments,.FlaggedPostComment,.GroupFlaggedPostComments {
  .ant-modal-body {
    padding: 37px 40px 25px 36px !important;

    .flagged_head {
      margin-bottom: 0 !important;
    }

    .main_flagged_postcmt {
      padding: 34px 0 0;

      .outer_flagged_row {
        margin-bottom: 10px;

        .detail {
          padding: 4px 0 0 10px !important;
        }
      }
    }
  }
}

.modal.ViewPostPopup {
  .ant-modal-body {
    padding: 37px 20px 25px 36px !important;

    .flagged_head {
      position: relative;
      display: block;
      margin-bottom: 0;

      .viewPost_back {
        position: absolute;
        cursor: pointer;

        &::after {
          content: '';
          position: absolute;
          border-top: 2px solid #000;
          border-left: 2px solid #000;
          width: 10px;
          height: 10px;
          display: block;
          top: 0;
          left: 4px;
          transform: rotate(316deg);
        }
      }
    }

    .main_flagged_postcmt {
      padding: 34px 0 0;
    }

    .inner_flagged_postcmt {
      .outer_flagged_row {
        .detail {
          width: 100%;
        }
      }
    }
  }
}

.modal.flaggedPostComments_popup.flagged_postBy_modal,
.modal.FlaggedPhyPostComments,
.modal.ViewPostPopup, .modal.FlaggedPostComment,
.modal.GroupFlaggedPostComments {
  .ant-modal-body {
    padding: 37px 20px 25px;

    .flagged_head,
    .main_flagged_postcmt {
      padding-right: 0;

    }
  }
}

.red_note {
  color: #c00c2d !important;
  margin: 22px 0 0;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;

}

.single_outer_flagged_row {
  margin: 0;
  padding-left: 58px;
}

.ant-tabs-tabpane {
  .content_wrapper {
    width: 100%;
  }
}

.physician_edit_modal {
  .pd_block {
    max-height: 70vh;
    overflow-y: auto;
  }
}

// sendpost modal css
.main_sendPostModal_modal {
  background: #FFFFFF;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 19px 9px;
  position: absolute;
  left: 122px;
  right: 0;
  top: 30px;
  /* transform: translate(-50%, 0); */
  max-width: 200px;
  width: 100%;
  z-index: 99;
  margin: 0 auto;

  span {
    cursor: pointer;
    font-family: ProximaNova_SemiBold;
    font-size: 12px;
    line-height: 12px;
    text-align: center;
    letter-spacing: 0.05em;
    text-transform: capitalize;
    color: #000000;
    display: block;
    padding: 10px 0;
    border-bottom: 1px solid #CED5DA;

    &:last-child {
      padding-bottom: 0;
      border: unset;
    }

    &:first-child {
      padding-top: 0;
    }

    &.report {
      color: #F24E1E;
    }
  }
}

#components-dropdown-demo-arrow .ant-btn {
  margin-right: 8px;
  margin-bottom: 8px;
}

.ant-row-rtl #components-dropdown-demo-arrow .ant-btn {
  margin-right: 0;
  margin-bottom: 8px;
  margin-left: 8px;
}


.post_delete_modal {
  padding: 5px 0 11px;

  .proceed {
    padding: 0 0 25px;
  }
}

.ant-modal.actionPopup,
.ant-modal.actionManagePopup,
.ant-modal.PromotionTypeFilter,
.ant-modal.PromotionFormatFilter,
.ant-modal.AddHashTags,
.ant-modal.EditHashTags,
.ant-modal.ActivationHashTag,
.ant-modal.UpdateStatusHashTag,
.ant-modal.DeleteHashTag,
.ant-modal.FlagPromoPopup,
.ant-modal.UpdateStatusPromotion,
.ant-modal.DeletePromotionUnit,
.ant-modal.HashTagNameFilter,
.ant-modal.HashTagStatusFilter,
.ant-modal.AddHashGroups,
.ant-modal.EditHashGroups,
.ant-modal.UpdateStatusHashGroup,
.ant-modal.DeleteHashGroup,
.ant-modal.FlagPostPopup,
.ant-modal.FlageedPostComment,
.ant-modal.FlagGroupPopup,
.ant-modal.GroupSortingPopup {
  max-width: 410px;
  width: 100% !important;

  .ant-modal-body {
    padding: 24px 20px 36px !important;

    .cg_action_modal_main {
      h2 {
        @extend .cg_comman_font_style;
        text-align: center;
        color: #000000;
        letter-spacing: 0;
        margin: 0 0 26px;
      }

      .cg_show_visibllity {
        max-width: 220px;
        width: 100%;
        margin: 0 auto 48px;
        padding: 0 0 0 20px;

        .cg_checkbox {
          position: relative;

          &:not(:last-child) {
            margin-bottom: 6px;
          }

          input {
            appearance: none;
            z-index: 9;
            position: absolute;
            width: 15px;
            height: 14px;
            cursor: pointer;

            &:focus {
              outline: none !important;
            }
          }

          label {
            padding-left: 28px;
            color: #457B9D;
            @extend .cg_comman_font_style;

            &::before {
              content: '';
              position: absolute;
              @extend .comman_label_before;
              left: 0;
              top: 2px;
              z-index: 1;
            }

            &::after {
              @extend .comman_label_after;
              top: 5px;
              left: 5px;
            }

          }

          input:checked+label:after {
            display: block;
          }
        }
      }
    }
  }
}

.ant-modal.ActivationHashTag {
  .ant-modal-body {
    padding: 24px 0px 36px 20px !important;
  }
}

.ant-modal.EditHashTags,
.ant-modal.AddHashTags,
.ant-modal.EditHashGroups,.ant-modal.UpdateStatusHashTag,
.ant-modal.AddHashGroups {
  .ant-modal-body {
    padding: 24px 42px 36px !important;
  }
}

.cg_comman_font_style {
  position: relative;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  text-transform: capitalize;
  font-family: "montserrat_semibold";
}

.FlaggedPhysicianItemsPopup,
.addPhysicianAdvanceFlags, .GroupPostList, .GroupFlaggedItemsPopup {
  .flagged_chemo_therapy_row {
    .main_detail_block {
      .detail {
        padding: 4px 0 0 10px;

        .tag_name {
          font-size: 11px;
          line-height: 11px;
        }

        .text {
          margin: 5px 0 0;
        }
      }
    }

    .inner_flagged_row {
      &::after {
        display: none;
      }
    }
  }
}

.FlaggedPhyPostComments,.FlaggedPostComment, .GroupFlaggedPostComments ,.ViewPostPopup{
  .main_flagged_postcmt {
    .inner_flagged_postcmt {
      .inner_flagged_row {
        &::after {
          display: none;
        }
      }
    }
  }
}

.read-or-hide {
  color: #C00C2D !important;
  font-family: ProximaNova_SemiBold !important;
}

.addPhysicianAdvanceFlags {
  .ant-modal-content {
    max-width: 529px;
    width: 100%;
  }

  .ant-modal-body {
    padding-top: 43px;
  }

  .main_detail_block {
    .detail {
      .tag_name {
        font-family: ProximaNova_Bold;
        font-size: 13.5px !important;
        line-height: 14px !important;
      }

      .text {
        font-family: ProximaNova_Regular;
        margin: 19px 0 6px !important;
        color: #495568;
        max-width: 330px;
        width: 100%;
      }
    }
  }

  .flagged_items_block {
    h2 {
      margin-bottom: 10px !important;
    }
  }

  .ant-modal-body .flagged_items_block {
    .flagged_items_list_block {
      padding-top: 56px;

      .main_flag_div {
        padding-top: 30px;
      }

      .single_outer_flagged_row {
        margin: 0;
        padding-left: 36px;
      }

    }

  }

}

.modal_dialog {
  .flagged_items_block {
    .promotion_popup {
      overflow-y: auto;

      .grpHash_list{
        width: fit-content;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin: 0 auto;

        div {
          margin-bottom: 10px;
          font-size: 16px;
        }
      }
      .list_of_members{
        margin-top: 10px;
        .main_flag_div {
          margin-left: 80px;
          margin-bottom: 10px;
        }
    }
      .outer_flagged_row {
        align-items: center;

        .user_profile {
          img {
            filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.15));
          }
        }
      }

      .main_detail_block {
        width: unset;
      }
      .main_flag_div_hash{
          margin-bottom: 10px;
          display: flex;
          align-items: flex-end;
          justify-content: space-between;
          margin-right: 20px;
       }
    }
  }
}

.organization-delete_modal {
  .delete_content {
    padding-bottom: 19px;

    p {
      max-width: 100%;
      line-height: 18px;
    }
  }
}


.model_checkbox {
  margin-left: 8px !important;
}

.ant-checkbox-wrapper {
  margin-bottom: 3px !important;
}

.ant-checkbox+span {
  padding-right: 8px;
  padding-left: 13px;
  font-size: 15px;
  font-weight: 600;
  color: #457B9D;
}

.ant-checkbox-checked .ant-checkbox-inner::after {
  border: 2px solid black !important;
  border-top: 0 !important;
  border-left: 0 !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #fff !important;
  border-color: #000 !important;
  color: #000;
}

.ant-modal.HashTagNameFilter {
  .cg_show_visibllity {
    padding: 0px !important;
  }
}

.main_passGen_modal {
  width: 100%;
  margin: 0 auto;

  h4 {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    text-transform: capitalize;
    color: #000000;
    padding-bottom: 16px;
    margin: 0;
  }

  .passgen_content p {
    font-size: 12px;
    line-height: 15px;
    text-align: center;
    color: #495568;
    width: 100%;
    margin: 15px auto;
    font-family: "montserrat_medium";
    font-weight: 500;
  }

  .passGen {
    font-weight: 600;
    font-size: 20px;
    line-height: 16px;
    text-align: center;
    color: #000000;
    display: block;
  }
}

.ant-modal-footer {
  padding: 0 !important;
  border: 0 !important;
}

.check_delete {
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #000000;
  display: block;
}

.check_delete_proceed {
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #000000;
  display: block;
}

.ant-modal.SelectUserPopup{
  .ant-modal-content{
    width: 600px;
    .ant-modal-body{
      padding: 0 !important;
      .modal_dialog{
  
        .select_user_modal{
          max-width: 600px;
          width: 100%;
          // position: absolute;
          left: 20%;
          // top: 50%;
          // left: 50%;
          // transform: translate(-50%,-50%);
          z-index: 1;
          background: $white;
          border-radius: 15px;
          padding: 37px 0px 35px 0px;
          // box-shadow: -4px 7px 57px -28px rgba(0,0,0,0.75);
            .select_user_modal_header{
              h4{
                font-family: montserrat_semibold;
                font-weight: 600;
                font-size: 16px;
                line-height: 20px;
                text-align: center;
                text-transform: capitalize;
                color: #25282C;
              }
              .close_btn{
                background: transparent;
                outline: none;
                border: none;
                position: absolute;
                right: 15px;
                top: 15px;
              }
              .header_checkBox{
                margin: 20px 0 18px 0;
                border-bottom: 1px solid #000000;
                .select_user_modal_container{
                  max-width: 506px;
                  width: 100%;
                  margin: 0 auto 18px auto;
  
                  .header_checkBox_wrapper{
                    @include flex;
                    .select_all_checkbox{
                      margin-top: unset !important;
                      margin-left: unset !important;
                      &.check_checkbox{
                        display: flex;
                        margin-top: 10px;
                        align-items: baseline;
                        margin-left: 26px;
                        position: relative;
                       label{
                         margin-left: 24px;
                         color: #457B9D;
                       }
                       input {
                         position: absolute;
                         opacity: 0;
                         cursor: pointer;
                         z-index: 1;
                         margin-top: 3px;
                       }
                       .checkmark {
                       position: absolute;
                       top: 0;
                       left: 0;
                       height: 14px;
                       width: 14px;
                       background-color:#FFFFFF;
                       border: 2px solid #595959;
                       margin-top: 3px;
                       &:after {
                         content: "";
                         position: absolute;
                         display: none;
                         left: 2px;
                         top: 0px;
                         width: 5px;
                         height: 8px;
                         border: solid black;
                         border-width: 0 3px 3px 0;
                         -webkit-transform: rotate(45deg);
                         -ms-transform: rotate(45deg);
                         transform: rotate(45deg);
                       }
                       }
                       input {
                         &:checked {
                           ~ {
                             .checkmark {
                               background-color:  #FFFFFF;
                               &:after {
                                 display: block;
                               }
                             }
                           }
                         }
                       }
                      }
                      label{
                        font-family: montserrat_semibold;
                        font-weight: 600;
                        margin-bottom: 0;
                        font-size: 14px;
                        line-height: 17px;
                        text-transform: capitalize;
                        color: #457B9D;
                        margin-left: 20px;
                      }
                    }
                    .total_selects{
                      span{
                        font-size: 14px;
                        font-family: montserrat_semibold;
                        font-weight: 600;
                        line-height: 17px;
                        text-transform: capitalize;
                        color: #457B9D;
                      }
                    }
                    .search_user{
                      .cg_search_form{
                        position: relative;
                        &.form_group{
                          margin-bottom: 0;
                        }
                        .search_user_field{
                          width: 100% !important;
                          min-width: 231px;
                          background-color: #FFFFFF;
                          padding: 9px 30px 8px 32px;
                          display: block;
                          min-height: auto !important;
                          letter-spacing: 0.7px;
                          border: 1.5px solid #C4C4C4;
                          border-radius: 20px;      
                        }
                        .serch_icon{
                          position: absolute;
                          top: 9px;
                          left: 7px;
                        }
                      }
                    }
                  }
                }
              }
              .list_of_users{
                border-bottom: 1px solid #000000;
                .users_lists{
                  margin-bottom: 62px;
                  padding: 0 0 0 47px;
                  .users_list_content{
                    display: flex;
                    align-items: center;
                    margin: 20px 0;
  
                    .check_checkbox{
                      display: flex;  
                      align-items: baseline;
                      position: relative;
                     label{
                       margin-left: 24px;
                       color: #457B9D;
                     }
                     input {
                      //  position: absolute;
                       opacity: 0;
                       cursor: pointer;
                       z-index: 1;
                       margin-top: 3px;
                     }
                     .checkmark {
                     position: absolute;
                     top: 0;
                     left: 0;
                     height: 14px;
                     width: 14px;
                     background-color:#FFFFFF;
                     border: 2px solid #595959;
                     margin-top: 3px;
                     &:after {
                       content: "";
                       position: absolute;
                       display: none;
                       left: 2px;
                       top: 0px;
                       width: 5px;
                       height: 8px;
                       border: solid black;
                       border-width: 0 3px 3px 0;
                       -webkit-transform: rotate(45deg);
                       -ms-transform: rotate(45deg);
                       transform: rotate(45deg);
                     }
                     }
                     input {
                       &:checked {
                         ~ {
                           .checkmark {
                             background-color:  #FFFFFF;
                             &:after {
                               display: block;
                             }
                           }
                         }
                       }
                     }
                    }
                    .users_profile_content{
                      display: flex;
                      .profile_pic{
                        margin-left: 39px;
                        border: 2px solid $white;
                        border-radius: 50%;
                        filter: drop-shadow(0px 0px 15px rgba(0, 0, 0, 0.15));
                        img{
                          width: 24px;
                          height: 24px;
                          border-radius: 50%;
                          object-fit: cover;
                        }
                      }
                      .profile_username{
                        margin-left: 14px;
                        width: 225px;
                        label{
                          font-family: ProximaNova_Medium;
                          font-weight: 500;
                          font-size: 16px;
                          line-height: 20px;
                          text-transform: capitalize;
                          color: #495568;
                        }
                      }
                      .users_LLO{
                        span{
                          font-family: ProximaNova_Medium;
                          font-weight: 500;
                          font-size: 16px;
                          line-height: 20px;
                          text-transform: capitalize;
                          color: #495568;
                        }
                      }
                    }
                   
                  }
                }
              }
              .paginations{
                .ant-pagination{
                  justify-content: center;
                  filter: unset !important;
                  // margin: 30px auto 46px !important;
                  margin-top: 30px !important;
                }
              }
              .add_btn{
                margin-top: 46px !important;
                text-align: center;
                .btn_add{
                  position: unset;
                  background: #C00C2D;
                  border-radius: 10px;  
                  font-family: montserrat_regular;
                  font-weight: 400;     
                  font-size: 14px;
                  line-height: 20px;
                  text-align: center;
                  letter-spacing: 0.3125px;
                  color: #FFFFFF;   
                  border: none;
                  outline: none;
                  padding: 11px 52px;
                  width: 154px;
                }
              }
            }
          
        }     
  }
  }
  }
}