.resetpssword_main {
  width: 100%;
  height: 100vh;
  // background: url(../images/resetpsw-bg.png) no-repeat;
  // background-size: cover;
  // background-position: center;

  @media (max-height: 500px) {
    height: 100%;
  }

  .resetpsw_sub_block {
    // @include width(600px);
    // margin: 0 auto;

    // @media (max-width: 991px) {
    //   @include width(300px);
    // }

    // .header {
    //   padding-bottom: 77px;

    //   img {
    //     display: block;
    //     max-width: 100%;
    //     width: 100%;
    //   }
    // }

    header.resetpsw_header{
      padding: 12px 20px 12px 52px;
      background-color: $white;
      border-bottom: 2px solid #CED5DA;
      .main_header_block{
        display: flex;
        align-items: center;
        @media (min-width: 1921px) {
          @include width(1440px);
          margin: 0 auto;
        }
      }
      .logo{
        @include width(95px);
        padding-right: 25px;
        position: relative;
        &::after{
          content: '';
          position: absolute;
          height: 40px;
          width: 1px;
          background-color: #C00C2D;
          right: 0;
          top: 50%;
          transform: translate(0, -50%);
        }
        img{
          @include width(70px);
          height: 54px;
        }
      }
      .header_txt{
        font-family: 'ProximaNova_SemiBold';
        font-size: 14px;
        line-height: 17px;
        letter-spacing: 0.02em;
        color: #3E7496;
        display: block;
        padding-left: 25px;
        @include width(310px);
      }
      @media (max-width: 480px) {
        padding: 15px 12px 14px 20px;
        .logo{
          padding-right: 18px;
          max-width: 93px;
        }
        .header_txt{
          font-size: 12px;
          line-height: 14px;
          padding-left: 16px;
          letter-spacing: 0;
        }
      }
    }

    .resetpsw_form_block {
      padding: 52px 20px 50px 52px;
      max-height: calc(100vh - 225px);
      overflow: auto;
      @media (min-width: 1921px) {
        @include width(1440px);
        margin: 0 auto;
        padding: 52px 20px 50px;
      }
      h4 {
        font-family: ProximaNovaBold;
        font-weight: 700;
        font-size: 14px;
        line-height: 14px;
        color: #495568;
        text-align: left;
        text-transform: capitalize;
        padding: 0 0 20px;
        margin: 0;
      }
      .ant-form-item{
        margin: 0;
        padding-bottom: 22px;
        &.confirm_psw_field{
          padding-bottom: 0;
          .ant-form-item-explain-error{
            color: #C00C2D !important;
          }
        }
        &.new_psw_field{
          .ant-form-item-explain-error{
            margin-bottom: -12px;
          }
        }
      }

      .resetpsw_form {
        @include width(304px);
        span.ant-input-affix-wrapper.ant-input-password {
          background: #EEEEEE !important;
          border-radius: 5px;
          border: none;
          padding: 9px 34px 9px 20px;

          input {
            font-family: ProximaNova_SemiBold;
            font-size: 14px;
            line-height: 14px;
            color: rgba(73, 85, 104, 0.8);
            background: #EEEEEE !important;

            &::placeholder {
              font-family: ProximaNova_SemiBold;
              color: rgba(73, 85, 104, 0.6);
            }

          }

          &:hover,
          &:focus {
            box-shadow: unset !important;
          }
        }
        .confirm_psw_field{
          .ant-input-affix-wrapper{
            margin: 0 0 6px;
          }
          .ant-form-item-explain-error {
            padding: 0;
          }
        }
        .ant-form-item-explain-error {
          padding: 6px 0 0;
        }
        .ant-row.ant-form-item:not(:first-child) {
          margin: 25px 0 0;
        }
        .form_buttons {
          display: flex;
          justify-content: flex-start;
          margin: 40px 0 0;
          button.btn {
            min-width: unset;
            @include width(102px);
            border-radius: 30px;
            padding: 14px 10px 13px;
            text-transform: capitalize;
            font-family: ProximaNova_Bold;
            &.btn_default {
              background: #CBCBCB;
              color: #3E4A58;
            }
          }
        }
        .ant-input-password{
          position: relative;
          svg{
            display: none;
          }
          .anticon-eye
          {
            width: 21px;
            height: 11px;
            &::after{
              @extend .comman_psw_style;
              background: url(../images/eye_visible_icon.svg) no-repeat;
              background-size: contain;
              width: 21px;
              height: 11px;
              top: 15px;
            }
            &-invisible{
              width: 21px;
              height: 11px;
              &::after{
                @extend .comman_psw_style;
                background: url(../images/eye_invisible_icon.svg) no-repeat;
                background-size: contain;
                width: 20px;
                height: 17px;
                top: 13px;
              }
            }
          }
        }
      }
      .successfully_msg {
        font-family: raleway_bold;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: rgba(73, 85, 104, 0.8);
        @include width(100%);
        padding: 0 0 20px;
      }

      @media (max-width: 480px) {
        padding: 30px 20px;
      }
      @media (max-width: 600px) {
        max-height: calc(100vh - 320px);
      }
      .psw_reset_msg{
        font-family: 'ProximaNova_Bold';
        font-size: 14px;
        line-height: 18px;
        color: #495568;
      }
    }

    footer.resetpsw_footer{
      padding: 24px 20px 24px 52px;
      background-color: #F5F5F5;
      position: fixed;
      width: 100%;
      bottom: 0;
      .resetpsw_footer_main{
        display: flex;
        @media (min-width: 1921px) {
          @include width(1440px);
          margin: 0 auto;
        }
      }
      @media (max-width: 680px) {
        padding: 42px 22px 30px;
        .resetpsw_footer_main{
          flex-direction: column;
        }
      }
      .footer_logo{
        @include width(170px);
        img{
          @include width(100px);
          height: 72px;
          display: block;
        }
        span{
          font-family: Poppins_semibold;
          font-size: 12px;
          line-height: 18px;
          letter-spacing: 0.05em;
          color: #000000;
          display: block;
          padding: 5px 0 0;
        }
        @media (max-width: 680px) {
          margin: 0 auto;
          img{
            margin: 0 auto;
          }
        }
      }
      .copyright_txt{
        font-family: Poppins_regular;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: 0.05em;
        color: #000000;
        align-self: flex-end;
        margin: 0;
        padding-left: 180px;
        width: calc(100% - 170px);
        @media (max-width: 991px) {
          padding-left: 100px; 
        }
        @media (max-width: 767px) {
          padding-left: 80px; 
        }
        @media (max-width: 680px) {
          padding-left: 0; 
          text-align: center;
          width: 100%;
          padding: 38px 0 0;
        }
      }
    }
  }
}

.ant-form-item-explain-error {
  font-family: ProximaNova_Regular;
  font-size: 11px;
  line-height: 12px;
  letter-spacing: 0.2px;
  //color: #2D2D2D !important;
  padding-top: 5px;
}

.ant-form-item-explain {
  min-height: unset;
}
.comman_psw_style{
  content: '';
  position: absolute;
  right: 8px;
}