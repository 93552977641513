@font-face {
  font-family: montserrat_thin;
  src: url('../fonts/Montserrat-Thin.woff2');
}

@font-face {
  font-family: montserrat_light;
  src: url('../fonts/Montserrat-Light.woff2');
}

@font-face {
  font-family: montserrat_regular;
  src: url('../fonts/Montserrat-Regular.woff2');
}

@font-face {
  font-family: montserrat_medium;
  src: url('../fonts/Montserrat-Medium.woff2');
  font-weight: 500;
}

@font-face {
  font-family: montserrat_semibold;
  src: url('../fonts/Montserrat-SemiBold.woff2');
}

@font-face {
  font-family: montserrat_bold;
  src: url('../fonts/Montserrat-Bold.woff2');
}

@font-face {
  font-family: ProximaNova_Regular;
  src: url('../fonts/ProximaNova-Regular.woff2');
  font-weight: 400;
}
@font-face {
  font-family: ProximaNova_Medium;
  src: url('../fonts/ProximaNovaMedium.woff2');
  font-weight: 500;
}

@font-face {
  font-family: ProximaNova_SemiBold;
  src: url('../fonts/Proxima_Nova_SemiBold.woff2');
  font-weight: 600;
}

@font-face {
  font-family: ProximaNova_Bold;
  src: url('../fonts/proximaNova-Bold.woff2');
  font-weight: 700;
}

@font-face {
  font-family: ProximaNovaBold;
  src: url('../fonts/ProximaNova-Regular.woff2');
}

@font-face {
  font-family: National_Ragular;
  src: url('../fonts/national-regular.ttf');
}


@font-face {
  font-family: raleway_medium;
  src: url('../fonts/Raleway-Medium.woff2');
}

@font-face {
  font-family: raleway_bold;
  src: url('../fonts/Raleway-Bold.woff2');
}

@font-face {
  font-family: sf_pro_display;
  src: url('../fonts/SF-Pro-Display.OTF');
}

@font-face {
  font-family: sf_pro_display;
  src: url('../fonts/SF-Pro-Display.OTF');
}

@font-face {
  font-family: Roboto;
  src: url('../fonts/Roboto-Regular.woff2');
}

@font-face {
  font-family: 'Gotham';
  src: url('../fonts/GothamMedium.eot'); /* IE9 Compat Modes */
  src: url('../fonts/GothamMedium.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
     url('../fonts/GothamMedium.woff2') format('woff2'), /* Modern Browsers */
     url('../fonts/GothamMedium.woff') format('woff'), /* Modern Browsers */
     url('../fonts/GothamMedium.ttf')  format('truetype'), /* Safari, Android, iOS */
     url('../fonts/GothamMedium.svg#svgFontName') format('svg'); /* Legacy iOS */
   font-weight: 500;
   font-style: normal;
}
@font-face {
  font-family: 'Gotham';
  src: url('../fonts/Gotham-Book.eot'); /* IE9 Compat Modes */
  src: url('../fonts/Gotham-Book.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Gotham-Book.woff2') format('woff2'), /* IE6-IE8 */
      url('../fonts/Gotham-Book.woff') format('woff'), /* Modern Browsers */
      url('../fonts/Gotham-Book.ttf')  format('truetype'), /* Safari, Android, iOS */
      url('../fonts/Gotham-Book.svg#svgFontName') format('svg'); /* Legacy iOS */
   font-weight: 400;
   font-style: normal;
}
@font-face {
  font-family: 'Gotham';
  src: url('../fonts/GothamBold.eot'); /* IE9 Compat Modes */
  src: url('../fonts/GothamBold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
     url('../fonts/GothamBold.woff2') format('woff2'), /* Modern Browsers */
     url('../fonts/GothamBold.woff') format('woff'), /* Modern Browsers */
     url('../fonts/GothamBold.ttf')  format('truetype'), /* Safari, Android, iOS */
     url('../fonts/GothamBold.svg#svgFontName') format('svg'); /* Legacy iOS */
   font-weight: 700;
   font-style: normal;
}



@font-face {
  font-family: Poppins_regular;
  src: url('../fonts/Poppins-Regular.woff2');
  font-weight: 400;
}
@font-face {
  font-family: Poppins_semibold;
  src: url('../fonts/Poppins-SemiBold.woff2');
  font-weight: 600;
}

.montserrat_thin {
  font-family: montserrat_thin;
  letter-spacing: .03rem;
}

.montserrat_medium {
  font-family: montserrat_medium;
  letter-spacing: .03rem;
}

.montserrat_light {
  font-family: montserrat_thin;
  letter-spacing: .03rem;
  font-weight: bold;
}

.montserrat_regular {
  font-family: montserrat_regular;
  letter-spacing: .03rem;
}

.montserrat_semibold {
  font-family: montserrat_semibold;
  letter-spacing: .03rem;
}

.montserrat_bold {
  font-family: montserrat_bold;
  letter-spacing: .03rem;
}



.raleway_medium {
  font-family: raleway_medium;
  letter-spacing: .03rem;
}

.raleway_bold {
  font-family: raleway_bold;
  letter-spacing: .03rem;
}

.sf_pro_display {
  font-family: sf_pro_display;
  letter-spacing: .03rem;
}