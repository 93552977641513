.main_clinic_header_block {
  background: rgba(154, 176, 189, 0.19);
  padding: 17px 20px 16px 80px;
  @media (max-width: 1024px) {
    padding-left: 20px;
  }
  &.pysicianicfo_block {
    .breadcrumb {
      flex-wrap: wrap;
    }
  }
}
.physicain_header_block {
  padding: 28px 20px 40px 80px;
  max-width: 825px;
  width: 100%;
  justify-content: space-between;
  @media (max-width: 1024px) {
    padding-left: 20px;
  }

  .clinic_form {
    padding: 0 10px 0 0;
    max-width: 500px;
    width: 100%;
  }
  .skip_for_now_btn {
    padding-top: 26px !important;
    width: calc(100% - 500px);
    button.btn_skip.btn {
      max-width: 154px;
      width: 100%;
      min-width: unset;
      padding: 11px 10px;
      text-align: center !important;
      display: block;
      margin-left: auto;
      letter-spacing: 0.9px;
    }
  }
  .card {
    padding: 0 !important;
    .form_row {
      max-width: 438px;
      width: 100%;
      .form_group {
        width: 50%;
        &:first-child {
          margin-right: 18px;
        }
        &:last-child {
          margin-left: 18px;
        }
      }
    }
    .form_group {
      max-width: 438px;
      width: 100%;
      .add_remove_block {
        align-items: center;
        flex-wrap: wrap;
        input {
          max-width: 438px;
          width: 100%;
        } 
      }
      input,
      .cursor_pointer,
      select {
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.9px;
        color: #3E4A58;
        font-family: montserrat_medium;
      }
      textarea {
        height: 160px;
        resize: none;
      }
      .cursor_pointer {
        color: #595959;
        position: unset;
        transform: unset;
        width: unset;
        padding: 5px 0 5px 10px;
      }

      .submit {
        color: #fff !important;
      }
      .clear_btn,
      .submit {
        padding: 10px !important;
      }
      &.main_add_remove_block {
        max-width: unset;
      }
    }
    .form_action {
      width: 100%;
      margin: 44px auto 0;
      padding-left: 52px;
      justify-content: unset;
      @media (max-width: 768px) {
        padding-left: 0;
      }
    }
  }

  @media (max-width: 991px) {
    & {
      flex-direction: column-reverse;
      .clinic_form {
        max-width: 100%;
        padding-right: 0;
      }
      .skip_for_now_btn {
        width: 100%;
        padding: 0 0 20px !important;
        .btn_skip {
          margin: 0 auto 0 0 !important;
        }
      }
    }
  }
}
.clinic_header {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  .breadcrumb {
    width: calc(50% - 5rem);
    display: flex;
    padding: 5px 0;
    margin: 0;
    @media (max-width: 991px) {
      width: auto;
    }
    li {
      list-style: none;
      color: rgba(0, 0, 0, 0.35);

      &.active {
        color: #000;
      }

      & + li {
        &:before {
          content: "";
          margin-left: 10px;
          padding-left: 20px;
          background-image: url("../images/arrow_right.svg");
          background-repeat: no-repeat;
          background-position: calc(100% - 1rem) center;
        }
      }
    }
  }
  .clinic_steps {
    width: 10rem;
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 5px 0;
    li {
      list-style: none;
    }
    span {
      width: 3.2rem;
      height: 3.2rem;
      font-size: 12px;
      background: #8cc7ec;
      color: #fff;
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 0.5rem;

      &.active {
        background: #457b9d;
        cursor: pointer;
      }
    }
  }
}

.form,
form {
  .col-3 {
    width: calc(25% - 1.8rem);
  }
  .col-4 {
    width: calc(33.33% - 1.8rem);
  }
  .col-5 {
    width: calc(41.66% - 1.8rem);
  }
  .col-6 {
    width: calc(50% - 1.8rem);
  }
  .col-7 {
    width: calc(58.33% - 1.8rem);
  }
  .col-8 {
    width: calc(66.66% - 1.8rem);
  }
  .col-9 {
    width: calc(75% - 1.8rem);
  }
  .col-12 {
    width: calc(100%);
  }
}

.clinic {
  position: relative;
  display: flex;
  //   flex-direction: column;
  .skip_for_now_btn {
    padding-top: 7rem;
  }
  &_form {
    width: calc(100% - 120px);
    padding-left: 20px;
    @media (max-width: 991px) {
      padding: 20px;
    }
  }
}
.card {
  padding: 2rem 2.5rem;
  @extend .fullWidth;

  &_bg_gray {
    background: #e8e8e8;
    border-radius: 3px;
  }
  &:first-child {
    padding: 0 0 16px 20px;
    @media (max-width: 991px) {
      padding-left: 0;
    }
  }
}

.form_control {
  border-radius: 1rem;
}
.form_row {
  display: flex;
  width: 100%;
  .col-6:not(:first-child) {
    margin-left: 30px;
  }
}
.cg_fl_name .form_group , .form_group.cg_email{
  margin-bottom: 25px;
}
.form_group {
  margin-bottom: 1rem;

  input{
    &::placeholder{
      font-weight: 400;
      font-family: 'montserrat_regular';
      letter-spacing: 0.3125px;
    }
  }
  label {
    margin-bottom: 7px;
    @extend .fullWidth;
    display: block;
    @extend .montserrat_regular;
    font-weight: 600;
  }

  .text_add {
    span {
      position: absolute;
      right: -8rem;
      top: 45%;
      transform: translateY(-50%);
      text-align: left;
      width: 7rem;
      text-transform: capitalize;
    }
    p{
      position: absolute;
      right: -4rem;
      top: 50%;
      transform: translateY(-50%);
      text-align: left;
      width: 7rem;
      color: #7c7c7c;
    }
  }
}
.form_action {
  margin-top: 7rem;
}

.fill {
  position: relative;
  &:after {
    content: "";
    height: 0.8rem;
    width: 0.8rem;
    float: right;
    background: $red;
    border-radius: 100%;
    overflow: hidden;
    top: 18px;
    right: -1.7rem;
    position: absolute;
  }
}
.valid {
  &:after {
    background: green;
  }
}
.fill_none {
  &:after {
    background: none;
  }
}

.sub_card_block {
  max-width: 350px;
  width: 100%;
  .form_row {
    .form_group {
      width: 50% !important;
      &:not(:first-child) {
        margin-left: 15px !important;
      }
      @media (max-width: 600px) {
        & {
          width: 100% !important;
          &:not(:first-child) {
            margin-left: 0 !important;
          }
        }
      }
    }
  }
}

span.cursor_pointer {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.9px;
  color: #595959;
  @extend .montserrat_regular;
}
.form_row {
  .form_group {
    width: 50%;
    &:first-child {
      margin-right: 18px;
    }
    &:last-child {
      margin-left: 18px;
    }
  }
  @media (max-width: 600px) {
    & {
      margin-bottom: 20px;
      flex-direction: column;
      .form_group,
      .clinic_data {
        width: 100% !important;
      }
      .form_group {
        &:first-child {
          margin: 0 0 10px !important;
        }
        &:last-child {
          margin: 10px 0 0 !important;
        }
      }
    }
  }
}
@media (max-width: 680px) {
  .form_group {
    select.form_control.col-3,
    select.form_control.col-5 {
      width: 50% !important;
    }
  }
  .form_action {
    margin: 20px auto 10px !important ;
  }
}
@media (max-width: 480px) {
  .form_action {
    flex-direction: column;
    max-width: 300px !important;
    .btn_primary {
      margin-left: 0;
    }
    .btn {
      margin: 5px 0 !important;
      width: 100% !important;
    }
  }
}
.card {
  @media (max-width: 600px) {
    .form_action {
      flex-direction: column;
      max-width: 300px !important;
      .btn_primary {
        margin-left: 0;
      }
      .btn {
        margin: 5px 0;
      }
    }
  }
}
.phone {
  &:after {
    background: none;
  }
}
.cursor_pointer {
  cursor: pointer !important;
}
.phone_type_patients {
  height: auto !important;

  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.3125px;
  color: #3e4a58;
  font-family: montserrat_medium;
  font-weight: 500;
  box-sizing: border-box;
}
.post_title_new{
  margin-bottom: 16px;
}
.post_dec_new{
  margin-bottom: 21px;
}
.loader-section-add-post{
  min-height: 700px;
}
.flag_wrapper{
  display: inline-block;
  span{
    display: inline-block;
    vertical-align: middle;
    .flag_icon{
      width: 16px;
      height: 20px;
      margin-right: 4px;
      display: block;
    }
  }
}