.specialist_tab {
  width: 100%;
  //width: calc(100% - 25rem);
  // overflow: auto;
  position: relative;

  &.addnew_phytab_main{
    width: 100%;
  }
  // .slick-slide,
  // .slick-track {
  //   width: 260px !important;
  // }
  .content_wrapper,
  .clinic {
    position: unset;
  }

  .ant-tabs {
    font-feature-settings: unset !important;
    font-variant: unset !important;
    &-nav {
      margin-bottom: 0 !important;
      background: rgba(154, 176, 189, 0.19);

      &-list {
        width: 100%;
        // padding: 0 0 0 50px;
        @media (max-width: 991px) {
          padding: 0 20px;
        }
        .ant-tabs-tab {
          @extend .montserrat_semibold;
          min-width: auto;
          // margin: 0 30px 0 0 !important;
          border: 0 !important;
          // padding: 0 !important;
          padding: 28px 0 27px !important;
          display: block;
          font-family: montserrat_bold;
          font-size: 14px;
          line-height: 20px;
          font-weight: 700;
          letter-spacing: 0.3125px;
          text-transform: uppercase;
          color: #5D737E;
          background-color: #ecf0f3 !important;
          // &:first-child{
            padding-left:50px !important;
            padding-right:50px !important;
          // }
          &:nth-last-child(2){
            margin: 0 !important;
          }
          @media (max-width: 991px) {
            min-width: unset;
            padding: 28px 20px !important;
          }
          &-active {
            text-decoration-line: none;
            background: $dark_blue !important;
            
            .ant-tabs-tab-btn {
              color: $white !important;
              text-shadow: none;
              &:focus {
                color: $white !important;
                text-decoration-line: none;
              }
            }
          }
        }
      }
    }

    &-tabpane {
      .content_wrapper {
        .physicain_header_block {
          padding: 40px 21px 40px 50px;

          @media (max-width: 991px) {
            min-width: unset;
            padding: 35px 30px 35px 24px;
          }
          .card{
            width: 100% !important;
            max-width: 570px;
          }
          .card_img{
            margin: 18px 0 0 -20px !important;
            @media (max-width: 991px) {
              margin: 18px 0 0 !important;
            }
          }
          .clinic_form {
            form{
              @media screen and (max-width: 880px) {
                flex-direction: column-reverse;
                .card_img{
                  margin: 0 auto 45px !important;
                }
                .card{
                  margin: 0 auto;
                  .form_row,  .form_group{
                    max-width: 100%;
                  }
                  .main_add_remove_block, .edit_form_group  {
                    input{
                      max-width: 478px;
                    }
                  }
                }
              }
            }
          }
        }

        .clinic {
          .clinic_form {
            width: 100%;
            max-width: unset;

            form {
              display: flex;

              .card {
                width: calc(100% - 130px);
                .cg_checkbox{
                  position: relative;
                  margin: 0 0 22px;
                  input{
                    appearance: none;
                    z-index: 9;
                    position: absolute;
                    width: 15px;
                    height: 14px;
                    cursor: pointer;
                    &:focus{
                      outline: none !important;
                    }
                  }
                  label{
                    @extend .montserrat_semibold;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 17px;
                    color: #595959;
                    padding-left: 26px;
                    &::before{
                      content: '';
                      position: absolute;
                      @extend .comman_label_before;
                      left: 0;
                      top: 4px;
                      z-index: 1;
                    }
                    &::after{
                      @extend .comman_label_after;
                      top: 7px;
                      left: 5px;
                    }
                  }
                  input:checked + label:after{
                    display: block;
                  }
                }
                select.form_control{
                  //option:first-child {
                  //  display: none !important;
                  //}
                }
              }

              .card_img {
                width: 100px;
                height: 100px;
                margin-left: 30px;

                .profile {
                  .user_profile_pic {
                    width: 100px;
                    height: 100px;
                    border-radius: 50%;
                    position: relative;

                    img {
                      width: 100%;
                      height: 100%;
                      border-radius: 50%;
                    }
                  }

                  .p-img {
                    display: inline-block;
                    position: absolute;
                    right: 0;
                    bottom: 0;

                    input {
                      width: 26px;
                      opacity: 0;
                      height: 26px;
                      position: absolute;
                      left: 0;
                      cursor: pointer;
                      z-index: 99;
                      padding: 14px;
                    }
                  }
                }
              }
            }
          }
        }

        .managepost {
          padding: 17px 20px 40px 50px;

          @media (max-width: 991px) {
            min-width: unset;
            padding: 35px 20px;
          }

          .heading_content {
            background: transparent;
            padding: 0;
          }

          .specialists_link_block {
            .btn {
              background: #495568;
              padding: 10px 20px !important;

              &:hover {
                background: transparent;
                color: #3e4a58;
              }
            }
          }

          &_content {
            max-width: 400px;
            overflow: auto;
            max-height: 74vh;

            // position: relative;
            &::-webkit-scrollbar-thumb {
              background: rgba(73, 85, 104, 0.5);
              border-radius: 20px;
            }

            &::-webkit-scrollbar-track {
              background: transparent;
            }

            &_detail {
              display: flex;
              margin-bottom: 15px;
              width: 100%;
              // position: relative;
            }

            .inner_flagged_row {
              padding-left: 0;
              max-width: 330px;
              align-items: unset;
              flex-wrap: wrap;

              .user_profile {
                width: 45px;
                max-width: unset;
                height: auto;
                position: relative;

                img {
                  max-width: 45px;
                  width: 100%;
                  height: 45px;
                }

                label {
                  display: inline-block;
                  position: absolute;
                  top: 38px;
                  right: 0;
                  left: 0;
                  margin: auto;
                  width: 20px;
                  height: 20px;
                  cursor: pointer;

                  img {
                    margin: auto;
                  }
                }

                &::after {
                  content: "";
                  height: calc(100% - 65px);
                  width: 1px;
                  background-color: #ced5da;
                  position: absolute;
                  left: 0;
                  right: 0;
                  bottom: 0;
                  margin: auto;
                  z-index: -2;
                }
              }

              .detail {
                padding-bottom: 12px;
                width: calc(100% - 45px);

                .name {
                  font-family: ProximaNovaBold;
                  color: #495568;
                  font-size: 13.5px;
                  line-height: 16px;
                  font-weight: 700;
                  margin-bottom: 5px;
                  display: block;
                }

                .subtitle {
                  display: block;
                  margin-bottom: 5px;
                }

                .time {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  position: relative;

                  img {
                    cursor: pointer;
                  }
                }

                .note_block {
                  margin-bottom: 20px;
                }

                .flagged_num {
                  position: absolute;
                  font-family: ProximaNova_Regular;
                  font-size: 12px;
                  line-height: 16px;
                  color: #ffffff;
                  padding: 4px 10px 4px;
                  background: #c00c2d;
                  border-radius: 100%;
                  top: -11px;
                  right: 0px;
                  z-index: 9;
                }

                .flagged_slider {
                  margin-top: 18px;
                  margin-bottom: 20px;

                  @include width(272px);
                  position: relative;

                  .image_block {
                    img {
                      border-radius: 10px;
                      max-width: 260px;
                      width: 100%;
                      height: 272px;
                      display: block;
                      object-fit: cover;
                    }
                  }

                  .slick-next,
                  .slick-prev {
                    width: auto;
                    height: auto;
                  }

                  // slider buttons css
                  .slick-prev {
                    left: -18px;

                    &:before {
                      content: "";
                      @extend .slider_button_style;
                      left: 0;
                    }
                  }

                  .slick-next {
                    right: -5px;

                    &:before {
                      @extend .slider_button_style;
                      transform: rotate(180deg);
                    }
                  }

                  .slider_button_style {
                    content: "";
                    background: url(../images/prev_icon.svg) no-repeat;
                    background-size: contain;
                    width: 8px;
                    height: 14px;
                    display: block;
                  }
                }

                .like_cmt_count_block {
                  display: flex;
                  align-items: center;

                  .lc_count_block {
                    display: inline-block;
                    margin-right: 18px;
                    vertical-align: middle;

                    &:last-child {
                      margin-right: 0;
                    }

                    img {
                      margin-right: 6px;
                      display: inline-block;
                      vertical-align: middle;
                    }

                    span {
                      display: inline-block;
                      vertical-align: middle;
                      background: rgba(73, 85, 104, 0.8);
                      padding: 2px 6px;
                      color: #fff;
                      border-radius: 20px;
                      font-size: 12px;
                      line-height: 15px;
                      font-family: "National_Ragular";
                    }
                  }

                }
                
                .cmt_click {
                  font-family: ProximaNova_SemiBold;
                  color: #457b9d;
                  font-size: 11px;
                  text-transform: uppercase;
                  display: inline-block;
                  vertical-align: middle;
                  cursor: pointer;
                }
              }
            }

            &_comment {
              display: flex;
              margin-bottom: 20px;
              width: 100%;
              // position: relative;

              .user_profile {
                img {
                  max-width: 36px !important;
                  height: 36px !important;
                  margin: auto;
                }

                .user_profile_comment {
                  img {
                    max-width: 17px !important;
                    width: 100% !important;
                    height: 25px !important;
                    object-fit: contain;
                  }
                }

                label {
                  top: 30px !important;
                }

                &::after {
                  height: calc(100% - 84px) !important;
                  bottom: 28px !important;
                }

                &_comment {
                  position: absolute;
                  bottom: 4px;
                  right: 0;
                  left: 0;
                }
              }

              .detail {
                padding-bottom: 0 !important;

                .like_cmt_count_block {
                  display: inline-block;
                  width: calc(100% - 45px);

                  .view_comment {
                    background-color: #457b9d;
                    color: #fff;
                    padding: 5px 10px;
                    border-radius: 20px;
                    display: inline-block;
                    cursor: pointer;
                  }
                }
              }
            }
          }

          &_detail {
            .heading_content {
              .specialists_link_back {
                display: flex;
                max-width: 350px;
                width: 100%;
                align-items: center;

                img {
                  width: 20px;
                }

                h3 {
                  width: calc(100% - 24px);
                  margin-bottom: 0;
                  text-align: center;
                  font-size: 16px;
                  font-family: ProximaNova_Bold;
                }
              }
            }

            .managepost_content_comment {
              .user_profile {
                &::after {
                  height: calc(100% - 65px) !important;
                  bottom: 0 !important;
                }
              }

              &:last-child {
                .user_profile {
                  &::after {
                    content: unset;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
.cg_comment_wrap{
  span{
    font-family: 'ProximaNova_SemiBold';
    font-size: 14px;
    line-height: 14px;
    text-transform: capitalize;
    color: #457B9D;
  }
}
            
.cg_liked_by_wrap{
  h6{
    font-family: 'ProximaNova_SemiBold';
    font-size: 11px;
    line-height: 11px;
    color: #457B9D;
    margin-bottom: 8px;
  }
  .cg_likedBy_profile{
    display: flex;
    .dot_circle_image{
      max-width: 25px;
      width: 100%;
      height: 25px;
    }
    .like_count_block {
      max-width: 25px;
      width: 100%;
      height: 25px;
      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 100%;
        border: 1px solid #D6D6D6;
      }
      &:not(:first-child){
        margin-left: -11px;
      }
    }
  }
}

.main_sendEdit_post {
  cursor: pointer;
}

.addpostpopup {
  max-width: 956px !important;
  width: 100% !important;
  max-height: 100vh !important;
  background-color: unset;
  box-shadow: unset;

  .ant-modal-content {
    margin: 0;
  }

  .ant-modal-body {
    padding: 40px 100px;
  }

  .ant-upload-list-picture-container:last-child,
  .ant-upload-list-picture-container:nth-child(4n) {
    .ant-upload-list-item-list-type-picture {
      margin-right: 0 !important;
    }
  }
}

.addpost_modal {
  padding: 25px 20px 25px 48px;
  &.addPost_modal_main{
    padding: 48px 20px 25px 48px;
  }
  @media (max-width: 991px) {
    &, &.addPost_modal_main{
      padding: 25px 20px;
    }
  }
  &_detail {
    // overflow-y: auto;
    height: auto;

    &_ctg {
      &_form {
        max-width: 470px;
        width: 100%;
        margin: 0 auto 0 0;

        &_title {
          display: block;
          width: 100%;
          color: #595959;
          font-size: 14px;
          letter-spacing: 0.31px;
          font-family: "montserrat_regular";
          font-weight: 400;
          margin-bottom: 16px;
        }

        &_list {
          display: inline-block;
          margin-bottom: 30px;

          input {
            display: none;
          }

          label {
            background-color: #e5e5e5;
            padding: 8px 18px;
            color: #3e4a58;
            font-family: ProximaNova_Bold;
            display: inline-block;
            margin-right: 10px;
            border-radius: 20px;
            cursor: pointer;
            font-size: 12px;
            text-transform: capitalize;
            letter-spacing: 0.5px;
          }

          input[type="radio"]:checked+label {
            background: #63bce1;
            color: #fff;
          }
        }

        .addpost_modal_detail_ctg_form_list {
          .errorMsg {
            margin-top: 5px;
          }
        }

        &_content {

          .post_title_block, .post_detail_block {
            margin-bottom: 16px;
            .errorMsg {
              margin-top: 5px;
            }
          }
          .post_detail_block{
            margin-bottom: 10px;
          }
          .post_txtarea_block{
            .ant-form-item-control-input{
              .ant-form-item-control-input-content{
                position: relative;
                .count-post{
                  position: absolute;
                  bottom: 4px;
                  right: 9px;
                  font-size: 12px;
                  line-height: 12px;
                  text-transform: capitalize;
                  color: #7C7C7C;
                  font-family: 'montserrat_medium';
                }
              }
            }
          }


          textarea.post_detail,
          .post_title {
            display: block;
            width: 100%;
            border: none;
            background-color: rgba(229, 229, 229, 0.5) !important;
            padding: 9px 12px 5px;
            border-radius: 10px;
            height: 54px;
            font-size: 14px;
            color: #3e4a58;
            line-height: 20px;
            resize: none;
            font-family: 'montserrat_medium';
            letter-spacing: 0;

            &::placeholder {
              color: #7c7c7c;
              font-family: 'montserrat_medium';
            }
            &::-webkit-scrollbar {
              width: 1px;
              display: none;
            } 
            &:focus{
              &::placeholder{
                opacity: 0;
              }
            }
          }

          textarea.post_detail {
            height: 435px;
          }
        }

        &_fileupload {
          .upload-list-inline {
            display: flex;
            justify-content: space-between;
          }

          .ant-upload-select-picture {
            width: 50px;

            span {
              button {
                padding: 0;
                border: none;
              }
            }
          }

          .ant-upload-list-picture {
            width: calc(100% - 50px);
            padding-left: 10px;
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-end;
            .ant-upload-list-item {
              position: relative;
              margin-right: 25px;
              margin-bottom: 10px;
              padding: 0;
              height: 75px;
              border: none;

              .ant-upload-list-item-name {
                display: none;
              }

              .ant-upload-list-item-card-actions {
                @extend .comman_delete_icon_style;
              }

              &-thumbnail {
                width: 72px;
                height: 72px;

                img {
                  width: 72px;
                  height: 72px;
                  border-radius: 10px;
                  object-fit: cover;
                }
              }
            }
          }
        }
        .ant-form-item-explain-error{
          padding: 8px 0 10px 6px;
        }
      }
    }
    .addpost_title_img{
      @include width(272px);
      padding: 10px 0 0 0;
      .ant-upload-select-picture-card{
        width: 100%;
        height: 100px;
        border-radius: 10px;
        border: 2px dashed rgba(0, 0, 0, 0.5);
        margin: 0 0 16px;
        .ant-upload{
          font-family: 'ProximaNova_Medium';
          font-size: 13px;
          line-height: 13px;
          text-align: center;
          text-transform: capitalize;
          color: #7C7C7C;
          padding: 10px 10px 18px;
          align-items: flex-end;
          position: relative;
          &::after{
            content: '';
            position: absolute;
            background: url(../images/upload.svg) no-repeat;
            background-size: contain;
            width: 24px;
            height: 24px;
            top: 50%;
            left: 0;
            right: 0;
            margin: 0 auto;
            transform: translate(0, -50%);
          }
        }
      }
      .ant-upload-list{
          position: relative;
          &-picture-card-container{
            position: absolute;
            margin: 0;
            padding: 0;
            width: 100%;
            height: calc(100% - 16px);
            display: block;
            .ant-upload-list-item{
              border: none;
              border-radius: 10px;
              padding: 0;
              .ant-upload-list-item-thumbnail{
                img{
                  object-fit: cover;
                  border-radius: 10px;
                }
              }
            }
          }
          &-item{
            position: relative;
            &:hover{
              .ant-upload-list-item-info{
                &::before{
                  display: none;
                }
              }
            }
          }
          &-item-actions{
            @extend .comman_delete_icon_style;
            right: -4px !important;
            & > a{
              display: none;
            }
          }
      }
    }
  }

  &_btn {
    max-width: 340px;
    width: 100%;
    margin: 7rem auto 1rem;
    button {
      width: 100%;
      min-width: unset;
      margin: 5px 8px;
    }
  }
  // .mentions__suggestions__item--focused{
  //   padding-top: 40px !important;
  // }
  .mentionLabel{
    text-align: center;
    font-size: 16px;
    line-height: 13px;
    font-weight: 600;
    background: #FFFFFF;
    height: 20px;
    margin: 10px 0;
    // position: sticky;
    // top: 0;
    .mentionClose_icon{
      float: right;
      width: 50px;
    }
  }
}
.addcommetpopup, .UserAndPhysicianSendMsgPop {
  max-width: 660px !important;
  width: 100% !important;
  max-height: 100vh !important;
  background-color: unset;
  box-shadow: unset;

  &.sendMsgPopup , &.UserAndPhysicianSendMsgPop {
    max-width: 410px !important;

    .ant-modal-body {
      padding: 20px 20px 30px;

      @media (max-width: 768px) {
        padding: 20px 20px 30px !important;
      }

      .modal_dialog {
        max-width: 324px !important;
        width: 100%;
        margin: 0 auto;

        h4 {
          font-family: montserrat_semibold;
          font-weight: 600;
          font-size: 14px;
          line-height: 19px;
          text-align: center;
          text-transform: capitalize;
          color: #000000;
          margin-bottom: 12px;
        }

        .addcomment_modal_detail_ctg_form_content {
          margin-bottom: 28px;

          textarea {
            min-height: 187px;
            background: #F1F3F9;
            border-radius: 5px;
            margin: 0;
            font-family: montserrat_medium;
            font-weight: 500;
          }

          .errorMsg {
            font-family: montserrat_medium;
            font-weight: 500;
            margin-top: 5px;
          }
        }

        .addcomment_modal_btn {
          margin: 0;
          width: 100%;
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          grid-column-gap: 16px;
          grid-row-gap: 10px;

          button {
            margin: 0 !important;
            font-family: 'montserrat_semibold';
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
          }

          @media (max-width: 480px) {
            grid-template-columns: repeat(1, 1fr);
          }
        }
      }
    }
  }

  .ant-modal-content {
    margin: 0;
  }

  .ant-modal-body {
    padding: 40px 100px;
  }

  .addcomment_modal {
    &_detail {
      &_ctg {
        &_form {
          max-width: 460px;
          width: 100%;
          margin: 0 auto;

          &_title {
            display: block;
            width: 100%;
            color: #595959;
            font-size: 14px;
            letter-spacing: 0.31px;
            font-family: "montserrat_regular";
            font-weight: 400;
            margin-bottom: 5px;
          }

          &_content {

            input,
            textarea {
              display: block;
              width: 100%;
              border: none;
              background-color: rgba(229, 229, 229, 0.5);
              padding: 10px 15px;
              border-radius: 10px;
              min-height: 63px;
              font-size: 12.5px;
              color: #7c7c7c;
              margin-bottom: 15px;
              resize: none;

              &::placeholder {
                color: #7c7c7c;
              }
            }

            textarea {
              min-height: 280px;
            }
          }
        }
      }
    }

    &_btn {
      button {
        margin-right: 15px;
      }
    }
  }
}
.addnew_org_images{
  .addpost_modal_detail_ctg_form_fileupload
  {
    .ant-upload-select-picture{
      width: 100%;
    }
    .ant-upload-list{
      &::before{
        display: none;
      }
    }
    .upload-list-inline{
      display: inline-block;
      .ant-upload-list-picture{
        justify-content: flex-start;
        padding: 0;
        width: 100%;
        margin-top: 14px;
        row-gap: 34px;
        .ant-upload-list-picture-container {
          margin: 0 36px 0 0;
          &:last-child{
            margin: 0;
          }
        }   
        .ant-upload-list-item{
          height: 124px;
          margin-right: 0;
        }
        .ant-upload-list-item-thumbnail{
          width: 124px;
          height: 124px;
          display: grid;
          place-content: center;
          img{
            width: 124px;
            height: 124px;
          }
        }
      }
    }
    .upload_btn{
      background: #495568;
          border-radius: 10px;
          color: #FFFFFF;
          font-family:montserrat_semibold;
          font-weight: 600;
          border: unset;
          height: 42px;
          padding: 11px 45px;
          // margin-right: 31px;
          // padding: 25px 85px;
          &:hover{
            cursor: pointer;
          }
    }
  } 
}

.comman_delete_icon_style{
    position: absolute;
    top: -10px;
    right: -8px;
    left: unset;
    opacity: 1;
    transform: unset;

    button {
      opacity: 1;
      span {
        background-image: url("../images/close_icon.svg");
        background-size: cover;
        width: 20px;
        height: 20px;

        svg {
          display: none;
        }
      }
    }
}