.psDetail_main_header {
  margin-left: auto;
  width: calc(100% - 250px);

  @media (max-width: 1200px) {
    margin: 0 auto;
    width: 100%;
  }

  .container_fluid.ps_detail_header {
    max-width: 815px;
    width: 100%;
    padding: 20px 10px 10px;

    @media (max-width: 1200px) {
      margin: 0 auto;
    }

    .main_herader_row {
      margin: 0;
      padding: 0;
      width: 100%;

      .header.physician_detail_logo.px_auto {
        padding: 0;

        .header .logo {
          padding: 0;
        }
      }
    }
  }
}

.ps-details_main_block {
  padding: 10px;
  width: calc(100% - 250px);
  margin-left: auto;

  @media (max-width: 1200px) {
    width: 100%;
    margin: 0 auto;
  }

  @media (max-width: 768px) {
    padding: 0;
  }

  .ps-details_container {
    @include width(795px);

    @media (max-width: 1200px) {
      margin: 0 auto;
    }

    .ps-details_header_main {
      padding: 10px 20px;
      background: rgba(154, 176, 189, 0.19);
      border: 1px solid rgba(0, 0, 0, 0.2);

      @media (max-width: 768px) {
        padding: 20px 10px;
        background: unset;
        border: unset;
      }

      @media (max-width: 350px) {
        padding: 10px 0;
      }
    }

    .ps-details_header {
      @include width(650px);
      margin: 0 auto;
      @include flex;

      .ps-details_title {
        h2 {
          font-family: montserrat_bold;
          font-weight: bold;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.3125px;
          text-transform: uppercase;
          color: #000000;
          padding-right: 10px;
          margin: 0;

        }
      }

      .ps-details_profile {
        display: flex;
        align-items: center;
      }

      .ps-details_name {
        width: calc(100% - 50px);
        padding-right: 16px;

        h4 {
          font-family: raleway_medium;
          font-weight: bold;
          font-size: 16px;
          line-height: 19px;
          text-align: right;
          color: #495568;
          text-transform: unset;
          margin: 0;
        }

        span {
          display: block;
          font-family: raleway_medium;
          font-weight: 500;
          font-size: 12px;
          line-height: 14px;
          text-align: right;
          color: #7C7C7C;
          @include width(150px);
          margin-left: auto;
        }
      }

      .ps-details_picture {
        @include width(50px);
        height: 50px;

        img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 50px;
        }
      }

      @media (max-width: 768px) {
        & {
          display: block;

          .ps-details_title {
            display: none;
          }
        }
      }

      @media (max-width: 350px) {
        & {
          .ps-details_profile {
            flex-direction: column-reverse;

            .ps-details_name {
              padding: 10px 0 0 0;

              h4,
              span {
                text-align: center;
                margin: 0 auto;
              }
            }
          }
        }
      }
    }

    .ps-details_biomain {
      @include width(666px);
      padding: 16px 10px 16px 74px;

      .ps-detail_bioheader {
        @include flex;

        h4 {
          font-family: raleway_bold;
          font-weight: bold;
          font-size: 14px;
          line-height: 16px;
          color: #495568;
        }

        .download-btn {
          border: none;
          background-color: unset;
          padding: 0 0 0 10px;

          img {
            @include width(16px);
            height: 20px;
            display: block;
          }
        }
      }

      .ps-details_biodetail {
        @include width(530px);
        padding: 18px 0 28px;

        p {
          font-family: raleway_medium;
          font-weight: 500;
          font-size: 11px;
          line-height: 13px;
          color: #3E4A58;

          &:not(:last-child) {
            padding-bottom: 15px;
          }
        }
      }

      .year_experiance {
        @extend .comman_title_style;
        padding-bottom: 18px;
      }

      .ps-details_educationmain {
        &:not(:last-child) {
          margin-bottom: 18px;
        }

        h4 {
          @extend .comman_title_style;
          text-transform: unset;
          padding-bottom: 7px;
        }

        .ps-details_list {
          margin-top: 0;

          ul {
            li {
              font-family: raleway_medium;
              font-weight: 500;
              font-size: 11px;
              line-height: 13px;
              color: #3E4A58;
              margin: 0 !important;
              padding-left: 12px;
              @include width(280px);

              &::before {
                top: 4px;
              }

              &:not(:last-child) {
                margin-bottom: 6px;
              }
            }
          }

        }
      }

      @media (max-width: 768px) {
        & {
          padding: 22px 25px 40px;
          max-width: 100%;
          background-color: rgba(229, 229, 229, 0.5);
        }
      }
    }
  }
}

.copy_url_msg {
  text-align: right;
  margin-top: 10px;

  span {
    color: red;
    text-transform: capitalize;
  }
}

.comman_title_style {
  font-family: raleway_bold;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: #495568;
  display: block;
  text-align: left;
}

.physician_detail_logo {
  img {
    height: auto !important;
  }
}

.title_img_wrapper_meta_main{
  margin-top: 22.5px;
  background: #E5E5E5;
  margin-bottom: 17px;
  .title_img_wrapper_meta{
    max-width: 100%;
    width: 100%;
    display: block;
    height: 275px;
    margin: 0;
    img {
      max-width: 100%;
      max-height: 100%;
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .pdf_wrapper{
    height: 100px;
    background-color: #B30B00;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      max-width: 100%;
      max-height: 100%;
      display: block;
      height: 100%;
    }
  }

  .meta_title{
    margin: 0;
    padding: 11px 15px;
    font-family: ProximaNova_Medium !important;
    //font-family: SF_Pro_Display_Medium;
    font-size: 14px;
    line-height: 19px;
    color: black !important;
  }
}