.pagination-container {
  background-color: white;
  filter: drop-shadow(0px 0px 15px rgba(0, 0, 0, 0.15));
  border-radius: 30px;
  padding: 8px 14px;
  margin: 20px auto;
  display: table;
  @media (max-width: 991px) {
    &{
      padding: 8px 10px;
    }
  }

  .pagination-item {
    height: 20px;
    text-align: center;
    margin: auto 1px;
    color: rgba(0, 0, 0, 0.87);
    display: inline-block;
    box-sizing: border-box;
    border-radius: 16px;
    line-height: 20px;
    font-size: 12px;
    min-width: 20px;
    position: relative;
    vertical-align: middle;
    font-family: "SF_Pro", sans-serif;
    font-weight: 600;
    @media (max-width: 991px) {
      &{
        margin: auto 2px;
      }
      &.dots{
        cursor: pointer;
        &:hover{
          color: #000;
        }
      }
    }

    &:first-child, &:last-child{
      margin: 0px;
      padding: 0;
      min-width: 16px;
      &:hover, &.selected{
        background-color: unset;
      }
    }


    &.dots:hover {
      background-color: transparent;
      cursor: default;
    }
    &:hover {
      background-color: #52BCE9;
      cursor: pointer;
      color: #fff;
    }

    &.selected {
      background-color: #52BCE9;
      color: #fff;
    }

    .arrow {
      &::before {
        position: absolute;
        content: '';
        display: inline-block;
        width: 22px;
        height: 22px;
        background-size: contain;
        background-repeat: no-repeat;
      }

      &.left::before {
        background-image: url(../images/fe_arrow-left.png);
        left: -7px;
        top: 50%;
        transform: translate(0, -50%);
      }

      &.right::before {
        background-image: url(../images/fe_arrow-right.png);
        right: -7px;
        top: 50%;
        transform: translate(0, -50%);
      }
    }

    &.disabled {
      pointer-events: none;

      &:hover {
        background-color: transparent;
        cursor: default;
      }
    }
  }
}


.ant-pagination{
  margin: 30px auto 46px !important;
  display: inline-block;
  width: auto;
  background-color: white;
  filter: drop-shadow(0px 0px 15px rgba(0, 0, 0, 0.15));
  border-radius: 30px;
  padding: 10px 4px !important;
  display: flex;
  align-items: center;
  align-self: flex-end;
  .ant-pagination-item{
    min-width: unset;
    height: unset;
    border: none;
    margin: 0 6px;
    a{  
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      text-transform: capitalize;
      color: #000000;
      font-family: "SF_Pro", sans-serif;
      padding: 0;
    }
    &.ant-pagination-item-active{
      background-color: #52BCE9;
      width: 18px;
      height: 18px;
      display: grid;
      place-items: center;
      border-radius: 100%;
      a{
        color: #fff;
      }
    }
  }
  .ant-pagination-prev, .ant-pagination-next{
    width: 20px;
    height: 20px;
    min-width: unset;
    margin: 0;
    .ant-pagination-item-link{
      font-size: 0;
      border: none;
      background-color: unset;
      width: 20px;
      height: 20px;
      position: relative;
      &::after{
        content: '';
        position: absolute;
        background: url(../images/fe_arrow-left.png) no-repeat;
        background-size: contain;
        background-repeat: no-repeat;
        display: inline-block;
        width: 20px;
        height: 20px;
        left: 0;
        top: 0;
      }
      .anticon{
        display: none;
        position: relative;
      }
    }
  }
  .ant-pagination-next{
    .ant-pagination-item-link{
      &::after{
        background: url(../images/fe_arrow-right.png) no-repeat;
        background-size: contain;
        background-repeat: no-repeat;
      }
    }
  }
  .ant-pagination-jump-next, .ant-pagination-jump-prev{
    min-width: 24px;
    height: auto;
    line-height: 1;
    margin-right: 2px !important; 
    span.ant-pagination-item-ellipsis{
      color: #000000 !important;
      bottom: unset !important;
      top: 3px !important;
      font-size: 12px !important;
      line-height: 14px !important;
    }
  }
  .ant-pagination-jump-prev{

  }
}